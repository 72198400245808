import { Injectable } from '@angular/core';
import { AbstractDialogService, DialogOptions } from '@examdojo/core/dialog';
import { FullscreenFlowComponent } from './fullscreen-flow.component';

const FULLSCREEN_FLOW_DIALOG_ID = 'fullscreen-flow';

@Injectable({
  providedIn: 'root',
})
export class FullscreenFlowDialogService extends AbstractDialogService<void, void, typeof FullscreenFlowComponent> {
  readonly id = FULLSCREEN_FLOW_DIALOG_ID;
  protected override readonly component = FullscreenFlowComponent;

  override options: DialogOptions = {
    cssClass: 'fullscreen action-sheet-on-mobile',
    canDismiss: true,
    backdropDismiss: false,
    showBackdrop: true,
    handle: false,
    breakpoints: undefined,
    initialBreakpoint: undefined,
  };
}
