import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractDialogService, canDismissWhenNotSwiped, DialogOptions } from '@examdojo/core/dialog';
import { mapToVoid } from '@examdojo/core/rxjs';
import { SolutionCaptureQueryParam } from '@examdojo/question';
import { from, switchMap } from 'rxjs';
import { RootUrlParts } from '../../../app.model';
import { TopicPracticeDialogContainerComponent } from './topic-practice-dialog-container.component';

@Injectable({ providedIn: 'root' })
export class TopicPracticeDialogService extends AbstractDialogService {
  readonly router = inject(Router);
  readonly id = 'topic-practice-dialog';
  readonly component = TopicPracticeDialogContainerComponent;

  override options: DialogOptions = {
    cssClass: 'modified-modal font-primary fullscreen',
    backdropDismiss: false,
    handle: false,
    breakpoints: undefined,
    initialBreakpoint: undefined,
    canDismiss: canDismissWhenNotSwiped,
  };

  dismissPracticeActivityDialog() {
    return from(this.dismiss()).pipe(
      switchMap(() =>
        this.router.navigate(['/', RootUrlParts.TopicPractice], {
          queryParams: {
            [SolutionCaptureQueryParam]: undefined,
          },
          queryParamsHandling: 'merge',
        }),
      ),
      mapToVoid(),
    );
  }
}
