<span
  dojoImagePreview
  [src]="dataUrl()"
  class="flex h-full w-full rounded-[0.75rem] bg-gray-500 bg-cover bg-center text-white dark:bg-gray-700"
  [style]="{
    'background-image': 'url(' + dataUrl() + ')',
    'background-size': 'cover',
    'background-position': '50%'
  }"
>
</span>

@if (editable()) {
  <dojo-icon-button
    (click)="removeImage.emit()"
    [small]="true"
    [icon]="'close'"
    [iconSize]="12"
    class="chat-images-remove-button absolute right-1 top-1"
  ></dojo-icon-button>
}
