import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@examdojo/core/icon';
import {
  STEM_DIFFICULTY_CATEGORY_TO_ICON,
  STEM_DIFFICULTY_CATEGORY_TO_LABEL_KEY,
  StemDifficultyCategory,
} from '@examdojo/models/question';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'dojo-difficulty',
  imports: [TranslocoPipe, IconComponent],
  templateUrl: './difficulty.component.html',
  styleUrl: './difficulty.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DifficultyComponent {
  @Input({ required: true }) difficulty!: StemDifficultyCategory;

  readonly STEM_DIFFICULTY_CATEGORY_TO_LABEL_KEY = STEM_DIFFICULTY_CATEGORY_TO_LABEL_KEY;

  readonly STEM_DIFFICULTY_CATEGORY_TO_CLASS: Record<StemDifficultyCategory, string> = {
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard',
  };

  protected readonly STEM_DIFFICULTY_CATEGORY_TO_ICON = STEM_DIFFICULTY_CATEGORY_TO_ICON;
}
