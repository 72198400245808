@if (form) {
  <dojo-swiper-stepper #stepper [submitFn]="submitFn" [swiperOptions]="swiperOptions" [form]="form" class="h-full">
    @for (step of steps; track $index) {
      <swiper-slide [controlName]="step.form && state.activeStepIndex === $index ? 'step-' + ($index + 1) : undefined">
        <div
          class="flex h-full flex-col justify-center"
          [hidden]="!componentRefs.get($index)?.instance?.isRendered() && state.activeStepIndex === $index"
        >
          <ng-container #componentSlot></ng-container>
        </div>
        @if (!componentRefs.get($index)?.instance?.isRendered() && state.activeStepIndex === $index) {
          <div class="flex h-full w-full items-center justify-center">
            <dojo-loader></dojo-loader>
          </div>
        }
      </swiper-slide>
    }

    <div controls class="footer-panel-wrapper">
      <dojo-footer-panel>
        <div left>
          @if (leftButtonTemplateRef(); as leftButtonTemplateRef) {
            <ng-container *ngTemplateOutlet="leftButtonTemplateRef"></ng-container>
          } @else {
            @if (state.activeStep?.leftButton; as button) {
              <dojo-button
                [swiperStepperPrevious]="button.disableStepControl !== true"
                class="small:grow-0 grow"
                [class]="button?.class ?? ''"
                [fill]="'outline'"
                @appearDisappear
              >
                @if (button.labelKey; as labelKey) {
                  {{ labelKey | transloco }}
                } @else {
                  <dojo-icon [icon]="'chevron-left'" class="h-6 items-center" />
                }
              </dojo-button>
            }
          }
        </div>

        <div right>
          @if (rightButtonTemplateRef(); as rightButtonTemplateRef) {
            <ng-container *ngTemplateOutlet="rightButtonTemplateRef"></ng-container>
          } @else {
            <dojo-button
              [swiperStepperNext]="state.activeStep?.rightButton?.disableStepControl !== true"
              class="small:grow-0 grow"
              [class]="state.activeStep?.rightButton?.class ?? ''"
              (click)="state.activeStep?.rightButton?.onClick?.(state.activeStep?.form?.value, stepper)"
            >
              {{ state.activeStep?.rightButton?.labelKey ?? 'continue' | transloco }}
            </dojo-button>
          }
        </div>
      </dojo-footer-panel>
    </div>
  </dojo-swiper-stepper>
}
