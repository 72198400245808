import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  model,
  OnInit,
  TemplateRef,
  Type,
  viewChild,
  viewChildren,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { getAnimationAppearDisappear } from '@examdojo/animation';
import { IconComponent } from '@examdojo/core/icon';
import {
  StepperSubmitFn,
  SwiperSlideDirective,
  SwiperStepperComponent,
  SwiperStepperNextDirective,
  SwiperStepperPreviousDirective,
} from '@examdojo/core/swiper';
import { ButtonComponent } from '@examdojo/ui/button';
import { LoaderComponent } from '@examdojo/ui/loader';
import { TranslocoPipe } from '@jsverse/transloco';
import { combineLatest, filter, map, startWith, switchMap, tap } from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { FooterPanelComponent } from '../footer-panel/footer-panel.component';
import { FullscreenFlowDialogService } from './fullscreen-flow-dialog.service';
import { FullscreenFlowStepComponent } from './fullscreen-flow-step-component';
import { FullscreenFlowStep } from './fullscreen-flow.model';

@Component({
  selector: 'dojo-fullscreen-flow',
  imports: [
    ButtonComponent,
    SwiperSlideDirective,
    SwiperStepperComponent,
    SwiperStepperNextDirective,
    SwiperStepperPreviousDirective,
    TranslocoPipe,
    FooterPanelComponent,
    IconComponent,
    NgTemplateOutlet,
    LoaderComponent,
  ],
  templateUrl: './fullscreen-flow.component.html',
  styleUrl: './fullscreen-flow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [getAnimationAppearDisappear()],
})
export class FullscreenFlowComponent implements OnInit {
  constructor(private readonly fullscreenFlowDialogService: FullscreenFlowDialogService) {
    this.renderComponents().pipe(takeUntilDestroyed()).subscribe();
  }

  @Input({ required: true }) steps!: Array<FullscreenFlowStep<FormGroup>>;
  @Input() initialStep: number | undefined;

  readonly leftButtonTemplateRef = model<TemplateRef<unknown>>();
  readonly rightButtonTemplateRef = model<TemplateRef<unknown>>();

  readonly componentContainers = viewChildren('componentSlot', { read: ViewContainerRef });
  private readonly stepper = viewChild(SwiperStepperComponent);

  private readonly stepper$ = toObservable(this.stepper).pipe(filter(Boolean));
  private readonly activeStepIndex$ = this.stepper$.pipe(switchMap((stepper) => stepper.activeSlideIndex$));

  readonly componentRefs = new Map<number, ComponentRef<FullscreenFlowStepComponent>>();

  private readonly activeStep$ = this.activeStepIndex$.pipe(map((index) => this.steps[index]));
  form?: FormGroup;

  readonly swiperOptions?: SwiperOptions = {
    slidesPerView: 1,
    pagination: false,
    allowTouchMove: false,
    keyboard: {
      enabled: true,
      onlyInViewport: true, // Set to false if you want it to work even when not in viewport
    },
  };

  readonly state = connectState({
    activeStepIndex: this.activeStepIndex$,
    activeStep: this.activeStep$.pipe(startWith(undefined)),
  });

  readonly submitFn: StepperSubmitFn = async () => {
    await this.fullscreenFlowDialogService.dismiss();
  };

  ngOnInit(): void {
    if (this.swiperOptions && this.initialStep) {
      this.swiperOptions['initialSlide'] = this.initialStep;
    }
    this.form = this.generateFormFromSteps();
  }

  private renderComponents() {
    return combineLatest([toObservable(this.componentContainers), this.activeStepIndex$]).pipe(
      tap(([viewContainerRefs, activeStepIndex]) =>
        viewContainerRefs.forEach((viewContainerRef, vcIndex) => {
          // Render the current component, if not already rendered
          // That can happen if the user navigates too fast
          if (vcIndex === activeStepIndex) {
            if (viewContainerRef.length === 0) {
              const currentComponent = this.steps[activeStepIndex].component as Type<FullscreenFlowStepComponent>;
              const componentRef = viewContainerRef.createComponent(currentComponent);

              this.componentRefs.set(vcIndex, componentRef);
            }

            return;
          }

          // Clear all other components
          else {
            viewContainerRef.clear();
            this.componentRefs.delete(vcIndex);
          }
        }),
      ),
      tap(([_, activeStepIndex]) => {
        const activeComponentRef = this.componentRefs.get(activeStepIndex);

        const otherComponentRefs = Array.from(this.componentRefs.entries())
          .filter(([index]) => index !== activeStepIndex)
          .map(([, componentRef]) => componentRef);

        otherComponentRefs.forEach((componentRef) => {
          componentRef.instance.isActive.set(false);
        });

        activeComponentRef?.instance.isActive.set(true);
      }),
    );
  }

  private generateFormFromSteps() {
    return new FormGroup(
      this.steps.reduce((acc, step, index) => {
        if (!step.form) {
          return acc;
        }
        return {
          ...acc,
          [`step-${index + 1}`]: step.form,
        };
      }, {}),
    );
  }
}
