import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionQuery } from '@examdojo/question';
import { filter, finalize, first, merge, switchMap, tap } from 'rxjs';
import { PostActivityScreensService } from '../../features/topic-practice-v2/post-activity-screens.service';
import { FullscreenFlowService } from '../../shared/fullscreen-flow';
import { QuestionAttemptRealtimeService } from '../question-attempt-realtime.service';

@Injectable()
export class QuestionAttemptFullscreenFlowEffectsService {
  constructor(
    private readonly fullscreenFlowService: FullscreenFlowService,
    private readonly postActivityScreensService: PostActivityScreensService,
    private readonly questionAttemptRealtimeService: QuestionAttemptRealtimeService,
    private readonly questionQuery: QuestionQuery,
  ) {
    merge(this.sendCustomDismissEventOnDismissOfFullScreenFlow(), this.openPostActivityWhenStatusChanged())
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  private sendCustomDismissEventOnDismissOfFullScreenFlow() {
    return this.fullscreenFlowService.dismissed$.pipe(
      tap(() => {
        this.questionAttemptRealtimeService.sendQuestionDismissDialogEvent();
      }),
    );
  }

  private openPostActivityWhenStatusChanged() {
    return this.questionQuery.questionLoaded$.pipe(
      filter((loaded) => !!loaded),
      switchMap(() => {
        return this.questionQuery.attemptStatus$.pipe(
          filter(
            (attemptStatus) =>
              attemptStatus === 'SUBMITTED' || attemptStatus === 'GRADED' || attemptStatus === 'FAILED',
          ),
          filter(() => !this.fullscreenFlowService.isOpen() && !this.questionQuery.getValue().openCaptureDialog),
          switchMap((attemptStatus) =>
            this.postActivityScreensService.postActivityScreens$.pipe(
              first(),
              switchMap((steps) => this.fullscreenFlowService.open(steps, attemptStatus === 'GRADED' ? 1 : 0)),
            ),
          ),
        );
      }),
      finalize(() => {
        if (this.fullscreenFlowService.isOpen()) {
          this.fullscreenFlowService.dismiss();
        }
      }),
    );
  }
}
