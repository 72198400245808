<div class="flex h-full flex-col focus-visible:outline-0" tabindex="0">
  <div class="flex-1 overflow-hidden">
    <div class="h-full">
      <ion-content #chat>
        <div class="flex h-full flex-col items-center gap-3 p-4">
          @if (state.isLoading) {
            <div class="flex h-full w-full items-center justify-center">
              <dojo-loader></dojo-loader>
            </div>
          } @else {
            @if (state.chatMessages && state.chatMessages.length > 0) {
              @for (item of state.chatMessages; track item.id; let last = $last) {
                <dojo-chat-message [message]="item" class="w-full" />
                @if (last) {
                  <div #last></div>
                }
              }
            } @else {
              <div class="chat-recommendation-container">
                <div class="chat-recommendation-title">
                  {{ 'chat.recommendation_title' | transloco }}
                  (<dojo-stem-marks-label [stemId]="stemId()" class="!inline-flex" />)?
                </div>

                <dojo-chat-sensei />

                <div class="chat-suggestion-button-container mt-2">
                  @for (suggestion of chatSuggestions; track $index) {
                    <ion-button class="chat-suggestion-button" (click)="sendSuggestion(suggestion.value)">
                      <dojo-icon class="mr-2" [size]="16" [icon]="suggestion.icon" [iconClass]="suggestion.color" />
                      {{ suggestion.label }}
                    </ion-button>
                  }
                </div>
              </div>
            }
          }
        </div>
      </ion-content>
    </div>
  </div>

  <!-- <dojo-chat-suggestions
    [disabled]="state.isResponseLoading"
    (submitSuggestion)="sendSuggestion($event)"
    class="w-full pt-2"
  /> -->

  <div class="w-full bg-white p-4 pt-0">
    <dojo-chat-input (submitMessage)="sendMessage($event)" class="w-full" />
  </div>
</div>
