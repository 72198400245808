<form
  [formGroup]="messageForm"
  (ngSubmit)="submit()"
  [ngClass]="{ 'bg-stone-200': isNonEmpty() }"
  class="chat-input-form relative flex w-full flex-col gap-3 rounded-[1rem] border border-neutral-200 bg-stone-100 p-3 transition hover:bg-stone-200"
>
  @if (imageList().length > 0) {
    <div class="images-container flex items-center gap-2 overflow-x-auto">
      @for (image of imageList(); track image.id) {
        <dojo-chat-images-list-item
          class="image-item h-14 w-14"
          [base64String]="image.dataUrl"
          [editable]="true"
          (removeImage)="removeChatInputPhoto(image.id)"
        />
      }
    </div>
  }

  <quill-editor
    #quillEditor
    [formControlName]="'message'"
    [format]="'json'"
    [modules]="quillModules"
    [bounds]="'self'"
    [placeholder]="'ask_question' | transloco"
    [theme]="'bubble'"
    [trimOnValidation]="true"
    [required]="true"
    class="chat-editor font-normal-exam !inline-flex w-full text-sm"
    [ngClass]="{ 'min-h-[44px] items-start': isNonEmpty(), 'min-h-[24px] items-center': !isNonEmpty() }"
    (onEditorCreated)="editorCreated($event)"
    (onContentChanged)="contentChanged($event)"
  />

  <div class="flex items-center justify-between">
    <dojo-icon-button
      (click)="addChatInputPhoto()"
      [icon]="'camera'"
      [transparent]="true"
      [disabled]="state.responseLoading"
      class="chat-submit-button"
    />

    <dojo-icon-button
      [type]="'submit'"
      [disabled]="!messageForm.controls.message.dirty || state.responseLoading"
      [icon]="'paper-plane-top'"
      class="chat-submit-button"
    />
  </div>
</form>
