import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { IconComponent } from '@examdojo/core/icon';
import { GradedStem, StemUIModel } from '@examdojo/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { AssessmentTopicWrapperComponent } from '../../features/assessments/assessment-topic-wrapper/assessment-topic-wrapper.component';
import { QuestionGradingMethodResultComponent } from './question-grading-method-result.component';

@Component({
  selector: 'dojo-question-grading-result',
  template: `
    @if (stemMarkscheme(); as stemMarkscheme) {
      <div
        class="flex flex-wrap items-center justify-between gap-4 border-b border-t border-neutral-200 bg-neutral-50 px-6 py-4"
      >
        <div class="flex flex-wrap items-center gap-4">
          @if (selfGradingAllowed()) {
            <dojo-button
              [customHeight]="40"
              [customWidth]="120"
              [fill]="'outline'"
              class="explain-button w-fit"
              [customColor]="'sky'"
              (click)="openChat.emit()"
            >
              <dojo-icon size="14" icon="sparkles" />
              <span class="ml-1 text-[14px]">{{ 'get_hint' | transloco }}</span>
            </dojo-button>

            <span class="text-sm text-neutral-700">{{ 'grading.select_marks' | transloco }} </span>
          } @else {
            <dojo-button
              [customHeight]="40"
              [customWidth]="120"
              [fill]="'outline'"
              class="explain-button w-fit"
              [customColor]="'sky'"
              (click)="openChat.emit()"
            >
              <dojo-icon size="14" icon="sparkles" />
              <span class="ml-1 text-[14px]">{{ 'grading.explain' | transloco }}</span>
            </dojo-button>
            <span class="text-neutral-200">|</span>
            <mat-slide-toggle
              class="small"
              color="primary"
              labelPosition="before"
              [checked]="showAlternatives()"
              (change)="showAlternatives.set($event.checked)"
            >
              <span class="whitespace-nowrap">{{ 'grading.show_alternative' | transloco }}</span>
            </mat-slide-toggle>
          }
        </div>
        @for (subStem of stemMarkscheme; track $index; let subStemIndex = $index) {
          @if (stemMarkscheme.length <= 1) {
            @if (subStem.topicLevel2Models.length > 0) {
              <div class="topic-display">
                <dojo-assessment-topic-wrapper [tl2s]="subStem.topicLevel2Models" class="ml-auto" />
              </div>
            }
          }
        }
      </div>

      @for (subStem of stemMarkscheme; track $index; let subStemIndex = $index) {
        @if (stemMarkscheme.length > 1 && subStem.label && subStem.topicLevel2Models.length > 0) {
          <div class="flex items-center justify-between px-6 py-3">
            <div class="text-neutral-1000 text-md font-semibold">
              @if (stemMarkscheme.length > 1 && subStem.label) {
                {{ 'grading.part' | transloco }} ({{ subStem.label }})
              }
            </div>

            @if (subStem.topicLevel2Models.length > 0) {
              <dojo-assessment-topic-wrapper [tl2s]="subStem.topicLevel2Models" />
            }
          </div>
        }

        @if (subStem.methods.length > 1) {
          <div>
            <mat-tab-group
              #tabGroup
              mat-stretch-tabs="false"
              mat-align-tabs="start"
              animationDuration="0ms"
              class="custom-mat-tab"
              dynamicHeight
              [selectedIndex]="
                !selfGradingAllowed() ? gradedStemResult()?.sub_stems?.[subStemIndex]?.awardedMethodIndex : 0
              "
            >
              @for (method of subStem.methods; track $index; let methodIndex = $index) {
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex items-center gap-2">
                      <span>{{ 'grading.method' | transloco }} {{ $index + 1 }}</span>
                      @if (
                        !selfGradingAllowed() &&
                        gradedStemResult()?.sub_stems?.[subStemIndex]?.methods?.[methodIndex]?.awarded
                      ) {
                        <span
                          [ngClass]="{
                            'bg-sky-100 text-sky-500': tabGroup.selectedIndex === methodIndex,
                            'text-disabled bg-neutral-100': tabGroup.selectedIndex !== methodIndex
                          }"
                          class="inline-flex rounded-[0.25rem] px-1 py-0.5 text-xs font-bold uppercase"
                          >{{ 'grading.yours' | transloco }}</span
                        >
                      }
                    </div>
                  </ng-template>
                  <div class="bg-neutral-50 p-4">
                    <dojo-question-grading-method-result
                      [methodMarkscheme]="method"
                      [methodAwarded]="
                        gradedStemResult()?.sub_stems?.[subStemIndex]?.methods?.[methodIndex]?.awarded ?? false
                      "
                      [gradedMethod]="gradedStemResult()?.sub_stems?.[subStemIndex]?.methods?.[methodIndex]"
                      [showAlternatives]="showAlternatives()"
                      [selfGradingAllowed]="selfGradingAllowed()"
                    />
                  </div>
                </mat-tab>
              }
            </mat-tab-group>
          </div>
        } @else {
          <div class="bg-neutral-50 p-4">
            <dojo-question-grading-method-result
              [methodMarkscheme]="subStem.methods[0]"
              [methodAwarded]="!!gradedStemResult()?.sub_stems?.[subStemIndex]?.methods?.[0]?.awarded"
              [gradedMethod]="gradedStemResult()?.sub_stems?.[subStemIndex]?.methods?.[0]"
              [showAlternatives]="showAlternatives()"
              [selfGradingAllowed]="selfGradingAllowed()"
            />
          </div>
        }
      }
    }
  `,
  imports: [
    MatTabGroup,
    ButtonComponent,
    MatTab,
    TranslocoPipe,
    IconComponent,
    QuestionGradingMethodResultComponent,
    AssessmentTopicWrapperComponent,
    MatSlideToggle,
    MatTabLabel,
    NgClass,
  ],
  host: {
    class: 'flex flex-col',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionGradingResultComponent {
  readonly gradedStemResult = input<GradedStem>();

  readonly selfGradingAllowed = input<boolean>();

  readonly stemMarkscheme = input.required<StemUIModel['markscheme']>();

  readonly showAlternatives = signal<boolean>(false);

  readonly openChat = output<void>();
}
