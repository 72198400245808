import { Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { StemStoreModel } from '@examdojo/models/question';
import { QuestionQuery, StemUIModel } from '@examdojo/question';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'dojo-stem-marks-label',
  standalone: true,
  styleUrl: './stem-marks-label.component.scss',
  template: `
    @if (state.result) {
      <span class="stem-order-tag">{{ state.result.label }}</span>
    }
  `,
  host: {
    class: 'flex items-center',
  },
})
export class StemMarksLabelComponent {
  private readonly questionQuery = inject(QuestionQuery);

  readonly stemId = input.required<StemStoreModel['id']>();

  private readonly questionItems$ = this.questionQuery.questionItems$;

  readonly state = connectState({
    result: combineLatest([this.questionItems$, toObservable(this.stemId)]).pipe(
      map(([items, stemId]) => {
        const stemsArray = items?.filter((item): item is StemUIModel => item.type === 'stem');

        const item = stemsArray?.find((stemsArrayItem) => stemsArrayItem.id === stemId);

        if (!item) {
          return;
        }

        return {
          label: item.label,
        };
      }),
    ),
  });
}
