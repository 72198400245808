import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { TranslocoPipe } from '@jsverse/transloco';
import { AgentRemoteMessageUIModel } from '../../models';

@Component({
  selector: 'dojo-agent-remote-message',
  template: `
    <div class="flex max-w-[90%]">
      <img
        src="assets/images/chat-logo.svg"
        class="w-[30px] self-start pt-[6px]"
        alt="{{ 'ask_question' | transloco }}"
      />
      <dojo-markdown-viewer
        [content]="message().message.text"
        class="message-text font-normal-exam text-md text-primary-exam overflow-x-auto overflow-y-hidden px-3 py-2"
      />
    </div>
  `,
  imports: [MarkdownViewerComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentRemoteMessageComponent {
  readonly message = input.required<AgentRemoteMessageUIModel>();
}
