import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { RiveDirective } from '@examdojo/ui/rive';
import { combineLatest, filter, fromEvent, map, tap } from 'rxjs';

@Component({
  selector: 'dojo-chat-sensei',
  imports: [RiveDirective],
  template: `
    <canvas
      #riveCanvas
      rive
      class="w-[200px]"
      loading="lazy"
      [riveFile]="'assets/images/responsive_hover_sensei.riv'"
      [artboard]="'Floating Sensei'"
      [stateMachine]="'State Machine 1'"
      [autoplay]="true"
      width="2000"
      height="2000"
    ></canvas>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSenseiComponent {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.cursorCoordinatesComparedToSensei$
      .pipe(
        tap(({ x, y }) => this.setEyesPosition(x, y)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  private readonly riveDirective = viewChild(RiveDirective);
  private readonly riveCanvas = viewChild<ElementRef<HTMLCanvasElement>>('riveCanvas');

  private readonly mouseMove$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
    map((event) => ({
      x: event.clientX,
      y: event.clientY,
    })),
  );

  private readonly cursorCoordinatesComparedToSensei$ = combineLatest([
    toObservable(this.riveCanvas).pipe(filter(Boolean)),
    this.mouseMove$,
  ]).pipe(
    map(([riveCanvasElement, { x: cursor_x, y: cursor_y }]) => {
      const rect = riveCanvasElement.nativeElement.getBoundingClientRect();

      const sensei_x = rect.left + rect.width / 2,
        sensei_y = rect.top + 50;

      const dx = cursor_x - sensei_x;
      const dy = sensei_y - cursor_y;

      let x = dx / (rect.width / 2);
      let y = dy / (rect.height / 2);

      x = Math.max(-1, Math.min(1, x));
      y = Math.max(-1, Math.min(1, y));

      return { x, y };
    }),
  );

  private setEyesPosition(x: number, y: number) {
    const stateMachine = this.riveDirective()?.riveInstance?.stateMachineInputs('State Machine 1');

    if (!stateMachine) {
      return;
    }

    const x_pos = stateMachine.find((stateInput) => stateInput.name === 'x_pos');
    const y_pos = stateMachine.find((stateInput) => stateInput.name === 'y_pos');

    if (x_pos && y_pos) {
      x_pos.value = x;
      y_pos.value = y;
    }
  }
}
