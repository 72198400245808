import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { QuestionActionDialogComponent } from './question-action-dialog.component';

export const QUESTION_ACTION_DIALOG_ID = 'question-action-dialog';

@Injectable()
export class QuestionActionDialogService extends AbstractDialogService<void> {
  readonly id = QUESTION_ACTION_DIALOG_ID;
  protected readonly component = QuestionActionDialogComponent;

  protected override readonly options = {
    cssClass: 'question-action-dialog auto-height action-sheet-on-mobile',
  };
}
