import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { TranslocoPipe } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';
import Quill from 'quill';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ChatImageService } from '../../chat-image.service';
import { ChatInputPhoto, ChatMessageContentStoreModel } from '../../models';
import { ChatMessageQuery } from '../../store';
import { ChatImagesListItemComponent } from '../chat-images-list-item/chat-images-list-item.component';

@UntilDestroy()
@Component({
  selector: 'dojo-chat-input',
  imports: [
    QuillEditorComponent,
    ReactiveFormsModule,
    ChatImagesListItemComponent,
    IconButtonComponent,
    TranslocoPipe,
    NgClass,
  ],
  providers: [ChatImageService],
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputComponent {
  constructor(
    private readonly chatMessageQuery: ChatMessageQuery,
    private readonly chatImageService: ChatImageService,
  ) {}

  @Output() submitMessage = new EventEmitter<ChatMessageContentStoreModel>();

  private readonly editorContent$$ = new BehaviorSubject<ContentChange | undefined>(undefined);

  readonly messageForm = new FormGroup<{ message: FormControl<string> }>({
    message: new FormControl<string>('', { nonNullable: true }),
  });

  readonly imageList = signal<ChatInputPhoto[]>([]);
  readonly isNonEmpty = signal(false);

  readonly quillModules: QuillModules = {
    toolbar: false,
    keyboard: {
      bindings: {
        Enter: {
          key: 'Enter',
          handler: () => {
            this.submit();
          },
        },
        EnterAndShort: {
          key: 'Enter',
          shortKey: true,
          handler: () => {
            this.submit();
          },
        },
      },
    },
  };

  readonly state = connectState({
    responseLoading: this.chatMessageQuery.selectResponseLoadingForCurrentStem(),
  });

  editorCreated(editorInstance: Quill) {
    setTimeout(() => {
      editorInstance.focus();
    }, 100);
  }

  contentChanged(content: ContentChange) {
    this.editorContent$$.next(content);
    const editorContent = this.getMessageContent(content);
    this.isNonEmpty.set(editorContent.trim().length > 0);
  }

  async addChatInputPhoto() {
    const photo = await this.chatImageService.takePicture();

    if (!photo || !photo.base64String) {
      return;
    }

    const lessThan3Mb = (photo.base64String.length * 3) / 4 < 3145728;

    if (!lessThan3Mb) {
      // TODO: show error toast
      return;
    }

    const chatInputPhoto: ChatInputPhoto = {
      id: uuid(),
      dataUrl: photo.base64String,
    };

    this.imageList.update((list) => [...list, chatInputPhoto]);
  }

  removeChatInputPhoto(id: string) {
    this.imageList.update((list) => list.filter((photo) => photo.id !== id));
  }

  submit() {
    if (this.state.responseLoading) {
      return;
    }

    const editorContent = this.editorContent$$.value;
    if (!editorContent) {
      return;
    }

    const messageText = this.getMessageContent(editorContent).trim();
    if (!messageText.length) {
      return;
    }

    this.submitMessage.emit({
      images: this.imageList().map((image) => image.dataUrl),
      text: messageText,
    });

    this.imageList.set([]);
    this.messageForm.reset();
  }

  private getMessageContent(contentChange: ContentChange): string {
    const editorContent = contentChange.content;
    let message = '';

    (editorContent?.['ops'] || []).forEach((chunk: { insert?: string | Record<string, unknown> }) => {
      if (typeof chunk.insert === 'string') {
        message += chunk.insert;
      }
    });

    return message;
  }
}
