import { ChangeDetectionStrategy, Component, ElementRef, inject, input, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { IconComponent } from '@examdojo/core/icon';
import { debounceFalsyValues } from '@examdojo/core/util/debounce-falsy-values-for-x-ms';
import { LoaderComponent } from '@examdojo/ui/loader';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import isEqual from 'lodash/isEqual';
import { tapOnce } from '@examdojo/core/rxjs';
import { combineLatest, delay, distinctUntilChanged, filter, map, merge, switchMap, tap } from 'rxjs';
import { ChatMessageService } from '../../chat-message.service';
import {
  ChatMessageContentStoreModel,
  EngagementPhase,
  PostGradingUserSuggestions,
  PreGradingUserSuggestions,
} from '../../models';
import { ChatMessageQuery } from '../../store';
import { ChatInputComponent } from '../chat-input/chat-input.component';
import { ChatMessageComponent } from '../chat-message/chat-message.component';
import { StemMarksLabelComponent } from '../stem-marks-label/stem-marks-label.component';
import { ChatSenseiComponent } from './chat-sensei/chat-sensei.component';

@Component({
  selector: 'dojo-chat',
  imports: [
    ChatMessageComponent,
    StemMarksLabelComponent,
    ChatInputComponent,
    TranslocoPipe,
    LoaderComponent,
    IconComponent,
    IonContent,
    IonButton,
    ChatSenseiComponent,
  ],
  templateUrl: './chat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex flex-col h-full' },
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  constructor(
    private readonly chatMessageQuery: ChatMessageQuery,
    private readonly chatMessageService: ChatMessageService,
  ) {
    merge(this.scrollToBottomOnNewMessage(), this.deleteLocalMessagesOnStemChange())
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly chatSuggestions = this.getChatSuggestionsBasedOnEngagementPhase(
    inject(ChatMessageQuery).getProp('engagementPhase'),
  );

  readonly chatId = input.required<number>();
  readonly stemId = input.required<number>();

  readonly stemId$ = toObservable(this.stemId);
  readonly chatId$ = toObservable(this.chatId);

  private readonly last = viewChild<ElementRef>('last');
  private readonly chat = viewChild.required<IonContent>('chat');

  private readonly chatMessages$ = this.stemId$.pipe(
    switchMap((stemId) => this.chatMessageQuery.selectMessages(stemId)),
    map((messages) => messages.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())),
    distinctUntilChanged((x, y) => isEqual(x, y)),
  );

  private readonly isResponseLoading$ = this.chatMessageQuery.selectResponseLoadingForCurrentStem();

  readonly state = connectState({
    chatMessages: this.chatMessages$,
    isLoading: this.chatMessageQuery.selectLoading().pipe(debounceFalsyValues(250)),
    isResponseLoading: this.isResponseLoading$,
  });

  sendMessage(message: ChatMessageContentStoreModel) {
    // TODO: Still listen even after the chat component is being destroyed.
    // Ideally the subscription should be unsubscribed when the question component is destroyed.
    this.chatMessageService
      .addUserMessage(this.stemId(), message)
      .pipe(
        tapOnce(() => {
          this.last()?.nativeElement.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }),
      )
      .subscribe();
  }

  sendSuggestion(suggestion: string) {
    // TODO: Still listen even after the chat component is being destroyed.
    // Ideally the subscription should be unsubscribed when the question component is destroyed.
    this.chatMessageService.addUserMessage(this.stemId(), { text: suggestion, images: [] }).subscribe();
  }

  private scrollToBottomOnNewMessage() {
    return combineLatest([
      this.chatMessageQuery.selectLoading().pipe(filter((isLoading) => !isLoading)),
      toObservable(this.chat).pipe(filter(Boolean)),
    ]).pipe(
      delay(300), // wait to render message history
      tap(([, chat]) => chat.scrollToBottom(250)),
    );
  }

  private deleteLocalMessagesOnStemChange() {
    return this.stemId$.pipe(tap((stemId) => this.chatMessageService.deleteLocallySavedMessagesForStem(stemId)));
  }

  private getChatSuggestionsBasedOnEngagementPhase(engagementPhase: EngagementPhase | null) {
    if (engagementPhase === EngagementPhase.PreGrading) {
      return PreGradingUserSuggestions;
    } else {
      return PostGradingUserSuggestions;
    }
  }
}
