import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

const ChatMarkedRendererHtmlTokenMap: Record<string, string> = {
  '<tip>': '<div class="example-block tip not-prose"><div class="example-block-text not-prose">',
  '</tip>': '</div></div>',

  '<note>': '<div class="example-block note not-prose"><div class="example-block-text not-prose">',
  '</note>': '</div></div>',

  '<warning>': '<div class="example-block warning not-prose"><div class="example-block-text not-prose">',
  '</warning>': '</div></div>',

  '<example_given>': '<div class="example-block example not-prose"><div class="example-block-text not-prose">',
  '</example_given>': '</div></div>',
};

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.html = (htmlBlock) => {
    const tagPattern = /<\/?(tip|note|warning|example_given)>/g;

    let hasCustomTags = false;

    const modifiedHtml = htmlBlock.raw.replace(tagPattern, (match) => {
      if (ChatMarkedRendererHtmlTokenMap[match]) {
        hasCustomTags = true;
        return ChatMarkedRendererHtmlTokenMap[match];
      }

      return match;
    });

    return hasCustomTags ? modifiedHtml : MarkedRenderer.prototype.html.call(renderer, htmlBlock);
  };

  return { renderer };
}

export const MarkedOptionsProvider = {
  provide: MARKED_OPTIONS,
  useFactory: markedOptionsFactory,
};
