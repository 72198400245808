import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { IconComponent } from '@examdojo/core/icon';
import { provideFaIcons } from '@examdojo/icons';
import { PlatformService } from '@examdojo/platform';
import { QuestionQuery } from '@examdojo/question';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import { IonContent, IonHeader, IonTitle, IonToolbar, ModalController, Platform } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { ChatMessageQuery } from '../../store';
import { ChatComponent } from '../chat/chat.component';
import { StemMarksLabelComponent } from '../stem-marks-label/stem-marks-label.component';

@Component({
  selector: 'dojo-chat-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ion-header class="mobile-header">
      <ion-toolbar class="!px-4">
        @if (state.isMobile) {
          <div slot="start" class="flex">
            <dojo-icon-button (click)="closeModal()" [icon]="'angle-left'"></dojo-icon-button>
          </div>
          <div slot="end" class="w-8"></div>
        } @else {
          <div slot="end" class="flex">
            <dojo-icon-button (click)="closed.emit()" [icon]="'times'"></dojo-icon-button>
          </div>
        }
        <ion-title class="common-ion-title small:!pl-0 !text-sm md:!text-left">
          <div class="flex items-center justify-center md:justify-start">
            <dojo-icon [icon]="'sparkles'" [size]="18" [iconClass]="'text-sky-500'"></dojo-icon>
            <span class="text-secondary ml-2"
              >{{ state.buttonText | transloco }}: (
              @if (state.stemId) {
                <dojo-stem-marks-label [stemId]="state.stemId" class="!inline-flex" />
              }
              )</span
            >
          </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content [scrollY]="false">
      <dojo-chat class="safe-area-bottom" [chatId]="state.chatId!" [stemId]="state.stemId!" />
    </ion-content>
  `,
  host: { class: 'flex flex-col h-full' },
  imports: [
    ChatComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IconButtonComponent,
    StemMarksLabelComponent,
    TranslocoPipe,
    IconComponent,
  ],
})
export class ChatDialogComponent {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly questionQuery: QuestionQuery,
    readonly chatMessageQuery: ChatMessageQuery,
    private readonly platformService: PlatformService,
    readonly platform: Platform,
  ) {
    provideFaIcons([faLightbulbOn]);
  }

  readonly stemId$ = this.chatMessageQuery.selectProp('stemId');
  readonly chatId$ = this.chatMessageQuery.selectProp('chatId');

  readonly state = connectState({
    stemId: this.stemId$,
    chatId: this.chatId$,
    buttonText: this.questionQuery.buttonText$,
    isMobile: this.platformService.isSmall$,
  });

  readonly closed = output();

  closeModal() {
    this.modalCtrl.dismiss();
    this.closed.emit();
  }
}
