<button
  class="question-selection-card text-md flex flex-col"
  [ngClass]="isSelected() ? 'question-selection-card--selected' : ''"
>
  @if (state.questionCard) {
    @if (isSelected()) {
      <div class="checkbox"><dojo-icon [icon]="'check'" [faPrefix]="'fas'" class="text-neutral-0"></dojo-icon></div>
    }
    <div class="mb-2 flex w-full justify-between">
      <span class="text-primary-exam font-semibold capitalize">{{ state.questionCard.questionDifficulty }}</span>
      <span class="text-secondary font-medium-exam">{{ state.questionCard.totalMarks }} marks</span>
    </div>
    <div class="mb-4 w-full rounded-[10px]">
      @if (state.questionCard.questionDifficulty) {
        <dojo-image [src]="STEM_DIFFICULTY_CATEGORY_TO_CARD_ICON[state.questionCard.questionDifficulty]" />
      }
    </div>

    <div class="question-selection-card__impact w-full">
      <div class="mb-3 flex justify-between gap-1 overflow-hidden">
        <span class="text-primary-exam font-medium-exam truncate">{{ 'topic_practice.score_impact' | transloco }}</span>
        <span class="shrink-0 font-bold text-green-600">
          +{{ state.questionCard.proficiency.gain | percent: '1.2-2' }}
        </span>
      </div>
      <dojo-question-selection-proficiency-bar
        [predictedScore]="state.questionCard.proficiency.predictedScore"
        [priorScore]="state.questionCard.proficiency.priorScore"
        class="mb-4 block"
      />
    </div>

    <div class="question-selection-card__topics w-full text-left">
      <div class="text-secondary font-medium-exam mb-3 text-sm">
        {{ 'topic_practice.topics_you_cover' | transloco }}
      </div>
      <dojo-question-selection-topic-list [questionTopicList]="state.questionCard.listOfTopicLevel2" />
    </div>
  } @else {
    <dojo-loader></dojo-loader>
  }
</button>
