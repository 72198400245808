<ion-content [scrollY]="false">
  @if (state.isLoading) {
    <dojo-loader class="flex h-full items-center justify-center" />
  } @else {
    @if (state.inProgressActivityId) {
      @if (state.questionLoaded) {
        <dojo-question-v2 @appearDisappear (completed)="handleCompletedQuestion($event)" />
      }
    } @else {
      @if (questionCandidates(); as questionCandidates) {
        <dojo-question-selection
          @appearDisappear
          [questionCandidates]="questionCandidates"
          (questionSelected)="handleQuestionSelected($event)"
          (cancelled)="handleCompletedQuestion('stop')"
        />
      }
    }
  }
</ion-content>
