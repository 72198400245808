import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { listStaggerAnimation } from '@examdojo/animation';
import { QuestionStoreModel, STEM_DIFFICULTIES } from '@examdojo/models/question';
import { PlatformService } from '@examdojo/platform';
import { PracticeActivityQuestionCandidatesStoreModel } from '@examdojo/practice-activity';
import { calculateQuestionDifficultyFromQuestionItems, QuestionItem, QuestionService } from '@examdojo/question';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonContent, IonHeader, IonTitle, IonToolbar, NavController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { combineLatest, debounceTime, filter, map, Observable, switchMap, tap } from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { FooterPanelComponent } from '../../../shared/footer-panel/footer-panel.component';
import { StoryCharacter } from '../../../shared/sensei/character.model';
import { SenseiComponent } from '../../../shared/sensei/sensei.component';
import { QuestionSelectionCardComponent } from './question-selection-card/question-selection-card.component';

@Component({
  selector: 'dojo-question-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './question-selection.component.html',
  styleUrls: ['./question-selection.component.scss'],
  animations: [listStaggerAnimation('.question-selection-card')],
  imports: [
    TranslocoPipe,
    QuestionSelectionCardComponent,
    FooterPanelComponent,
    IonContent,
    SenseiComponent,
    IconButtonComponent,
    IonHeader,
    IonTitle,
    IonToolbar,
  ],
  host: {
    class: 'flex h-full flex-col overflow-hidden justify-between',
  },
})
export class QuestionSelectionComponent {
  constructor(
    private readonly navCtrl: NavController,
    private readonly route: ActivatedRoute,
    private readonly platformService: PlatformService,
    private readonly questionService: QuestionService,
  ) {
    // Set initial selected question when questionCandidates input changes
    toObservable(this.questionCandidates)
      .pipe(
        filter((candidates) => candidates.length > 0),
        tap((candidates) => {
          this.selectedQuestionId.set(candidates[0].question_id);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly activeStoryCharacter: StoryCharacter = {
    file: 'assets/images/onboarding/hovering_sensei.riv',
    artboard: 'Floating Sensei',
    stateMachine: 'State Machine 1',
    isBackground: false,
    isCentered: false,
    customStyles: {
      width: 'initial',
      height: '100%',
      objectFit: 'contain',
      position: 'relative',
      top: '0px',
      left: '0',
      'margin-left': '-30px',
    },
  };

  readonly currentQuestionProficiencyGain$ = computed(() => {
    const questionId = this.selectedQuestionId();
    const questionCandidate = this.questionCandidates().find((q) => q.question_id === questionId);

    if (!questionCandidate) {
      return null;
    }

    return (
      ((questionCandidate.metadata.predicted_score - questionCandidate.metadata.prior_predicted_score) /
        questionCandidate.metadata.prior_predicted_score) *
      100
    ).toFixed(2);
  });

  readonly activeSpeechBubble = computed(() => {
    const gain = this.currentQuestionProficiencyGain$();
    return {
      text: gain
        ? `If you outperform the baseline, your predicted score is going to increase by ${gain}%.`
        : `Select question, based on our predicted score impact`,
      translationParams: {},
    };
  });

  readonly selectedQuestionId = signal<QuestionStoreModel['id'] | null>(null);

  readonly questionCandidates = input.required<PracticeActivityQuestionCandidatesStoreModel[]>();

  private readonly questionCandidatesWithItems$: Observable<
    Array<PracticeActivityQuestionCandidatesStoreModel & { items: QuestionItem[] }>
  > = toObservable(this.questionCandidates).pipe(
    switchMap((questionCandidates) =>
      combineLatest(
        questionCandidates.map((questionCandidate) =>
          this.questionService
            .fetchQuestionItems(questionCandidate.question_id, false)
            .pipe(map((items) => ({ ...questionCandidate, items }))),
        ),
      ),
    ),
  );

  readonly questionSelected = output<QuestionStoreModel['id']>();
  readonly cancelled = output<void>();

  readonly swiperOptions: SwiperOptions = {
    effect: 'cards',
    cardsEffect: {
      slideShadows: false,
    },
    grabCursor: true,
  };

  readonly disablePrev = signal<boolean>(true);
  readonly disableNext = signal<boolean>(false);

  readonly state = connectState({
    activeSpeechBubble: toObservable(this.activeSpeechBubble).pipe(debounceTime(0)),
    isMobile: this.platformService.isSmall$,
    sortedQuestionCandidates: this.questionCandidatesWithItems$.pipe(
      map((candidates) =>
        candidates
          .map((candidate) => ({
            ...candidate,
            difficulty: calculateQuestionDifficultyFromQuestionItems(candidate.items),
          }))
          .toSorted((a, b) => {
            const difficultyOrder = STEM_DIFFICULTIES;
            return difficultyOrder.indexOf(a.difficulty ?? 'EASY') - difficultyOrder.indexOf(b.difficulty ?? 'EASY');
          }),
      ),
    ),
  });

  selectQuestionCard(id: QuestionStoreModel['id']) {
    this.selectedQuestionId.set(id);
  }

  submit() {
    const selectedQuestionId = this.selectedQuestionId();
    if (!selectedQuestionId) {
      return;
    }
    this.questionSelected.emit(selectedQuestionId);
  }

  navigateToParent(): void {
    this.navCtrl.navigateBack(['../'], { relativeTo: this.route });
  }

  cancel() {
    this.cancelled.emit();
  }
}
