import { AsyncPipe, LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, output, ProviderToken, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { getAnimationAppearDisappear, getAnimationAppearDisappearWithHeight } from '@examdojo/animation';
import { SyllabusQuery } from '@examdojo/category/v2';
import { DateTimeService } from '@examdojo/core/date-time';
import { DebuggingContextService } from '@examdojo/core/debugging';
import { LocalizePipe, LocalizeService } from '@examdojo/core/i18n';
import { IconComponent, SvgIcon } from '@examdojo/core/icon';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { provideFaIcons } from '@examdojo/icons';
import { PlatformService } from '@examdojo/platform';
import {
  calculateQuestionDifficultyFromQuestionItems,
  convertStemMarkschemeUIModelToGradedMarkscheme,
  QuestionFlagFeedbackDirective,
  QuestionQuery,
  QuestionService,
  SolutionCaptureDialogService,
  StemUIModel,
} from '@examdojo/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { DifficultyComponent } from '@examdojo/ui/difficulty';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { ImagePreviewDirective } from '@examdojo/ui/image-preview';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { UserQuery } from '@examdojo/user';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons';
import { faBookmark, faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonTitle,
  IonToolbar,
  ModalController,
  NavController,
} from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { AngularSplitModule } from 'angular-split';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  map,
  merge,
  switchMap,
  tap,
} from 'rxjs';
import { ChatMessageService, EngagementPhase } from '../chat';
import { ChatDialogComponent } from '../chat/components/chat-dialog/chat-dialog.component';
import { ChatDialogService } from '../chat/components/chat-dialog/chat-dialog.service';
import { FooterPanelComponent } from '../shared/footer-panel/footer-panel.component';
import { QuestionAttemptFullscreenFlowEffectsService } from './effects/question-attempt-fullscreen-effects.service';
import { QuestionActionDialogService } from './question-action-dialog/question-action-dialog.service';
import { QuestionGradingResultComponent } from './question-grading-result';
import { QuestionGradingResultHeaderComponent } from './question-grading-result/question-grading-header.component';

const MOBILE_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1300;

@Component({
  selector: 'dojo-question-v2',
  imports: [
    IonContent,
    TranslocoPipe,
    ButtonComponent,
    IonButton,
    IonImg,
    LocalizePipe,
    AsyncPipe,
    MarkdownViewerComponent,
    DifficultyComponent,
    IconComponent,
    ImagePreviewDirective,
    QuestionFlagFeedbackDirective,
    AngularSplitModule,
    ChatDialogComponent,
    NgTemplateOutlet,
    LowerCasePipe,
    MatTooltip,
    FooterPanelComponent,
    QuestionGradingResultComponent,
    NgClass,
    QuestionGradingResultHeaderComponent,
    IonHeader,
    IconButtonComponent,
    IonTitle,
    IonToolbar,
  ],
  providers: [ChatDialogService, QuestionAttemptFullscreenFlowEffectsService, QuestionActionDialogService],
  templateUrl: './question-v2.component.html',
  styleUrl: './question-v2.component.scss',
  host: { class: 'ion-page' },
  animations: [getAnimationAppearDisappearWithHeight(), getAnimationAppearDisappear()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionV2Component {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly solutionCaptureDialogService: SolutionCaptureDialogService,
    private readonly questionService: QuestionService,
    private readonly questionQuery: QuestionQuery,
    private readonly destroyRef: DestroyRef,
    private readonly chatDialogService: ChatDialogService,
    private readonly platformService: PlatformService,
    private readonly chatMessageService: ChatMessageService,
    private readonly modalCtrl: ModalController,
    private readonly navCtrl: NavController,
    private readonly dateTimeService: DateTimeService,
    private readonly debuggingContextService: DebuggingContextService,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly localizeService: LocalizeService,
    private readonly assessmentActionDialogService: QuestionActionDialogService,
    private readonly userQuery: UserQuery,
  ) {
    merge(
      this.reactToResize(),
      this.shouldOpenSolutionCaptureDialogOnDialogFlag(),
      this.closeDesktopChatWhenGraded(),
      this.debuggingContextService.syncDebuggingContext('Question', this.question$),
      this.debuggingContextService.syncDebuggingContext('Attempt', this.questionQuery.attempt$),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
    provideFaIcons([faBookmark, faLightbulb, faLightbulbOn]);
  }

  private readonly instantiatedServices = [QuestionAttemptFullscreenFlowEffectsService].map(
    (service: ProviderToken<unknown>) => inject(service),
  );

  readonly singleColumnIcon: SvgIcon = 'assets/images/topic-practice/single-columns.svg';
  readonly activeItemId = signal<number | null>(null);
  readonly isDesktopChatVisible = signal(false);
  readonly isLayoutTwoCols = signal(false);
  readonly openedGradingResultItemId = signal<number | null>(null);

  readonly completed = output<'continue' | 'stop'>();

  readonly sizeWildcard = '*' as unknown as number;
  readonly DRAG_TARGET = 1;

  readonly MIN_LEFT_NAV_WIDTH_PX = 300;
  readonly MAX_LEFT_NAV_WIDTH_PX = 800;
  readonly INITIAL_LEFT_NAV_WIDTH_PX = 480;

  private readonly question$ = this.questionQuery.select('question');
  private readonly questionItems$ = this.questionQuery.questionItems$;
  private readonly questionAttemptStatus$ = this.questionQuery.attemptStatus$;

  readonly isMobile = this.platformService.isMobile;
  readonly isTablet = this.platformService.isTablet;

  readonly state = connectState({
    question: this.question$,
    questionFlagged: this.questionQuery.questionFlagged$,
    questionItems: this.questionItems$,
    totalMarks: this.questionQuery.questionGradingTotalPoints$,
    awardedMarks: this.questionQuery.questionGradingAwardedPoints$,
    attempt: this.questionQuery.select('attempt'),
    attemptStatus: this.questionAttemptStatus$,
    selfGradingAllowed: this.questionQuery.selfGradingAllowed$,
    buttonText: this.questionQuery.buttonText$,
    timeSpentAmount: this.questionQuery.timeSpent$,
    isMobile: this.platformService.isSmall$,
    formulaBooklet: this.syllabusQuery.active$.pipe(
      map((syllabus) => {
        return this.localizeService.localizeOnce(syllabus?.formular_booklet ?? {});
      }),
    ),
    timeSpent: this.questionQuery.timeSpent$.pipe(
      map((x) => {
        if (!x) {
          return '';
        }

        return this.dateTimeService.formatDurationShort(x);
      }),
    ),
    showColumnSpliter: this.questionItems$.pipe(
      map((items) => {
        if (!items) {
          return null;
        }
        let score = 0;
        for (const item of items) {
          if (item.type === 'stem' || item.type === 'stimulus') {
            score += 1;
          } else if (item.type === 'question_image') {
            score += 2;
          }
        }
        return score > 6;
      }),
    ),
    difficulty: this.questionItems$.pipe(map((items) => calculateQuestionDifficultyFromQuestionItems(items))),
  });

  navigateToParent(): void {
    // TODO: complete the activity
    this.navCtrl.navigateBack(['../'], { relativeTo: this.route });
  }

  openSolutionCaptureDialog() {
    if (this.solutionCaptureDialogService.isOpen()) {
      return;
    }

    const attemptId = this.state.attempt?.id;
    const userId = this.userQuery.getActiveId();
    assertNonNullable(attemptId, 'attemptId');
    assertNonNullable(userId, 'userId');
    const previousAttemptStatus = this.state.attempt?.status;
    assertNonNullable(previousAttemptStatus, 'previousAttemptStatus');
    from(
      this.solutionCaptureDialogService.openDialog({
        input: {
          userId,
          attemptId,
        },
      }),
    )
      .pipe(
        switchMap((data) => {
          if (!data) {
            return EMPTY;
          }
          const preset = data === true ? undefined : data;
          return this.questionService.submitForGrading(attemptId, preset).pipe(catchError(() => EMPTY));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  selfGradeQuestionAttempt() {
    const attemptId = this.state.attempt?.id;
    assertNonNullable(attemptId, 'engagementId');

    const gradableMarkscheme =
      this.state.questionItems
        ?.map((item) => {
          if (item.type !== 'stem') {
            return null;
          }

          return item;
        })
        .filter(isNotNullish) ?? [];

    this.questionService
      .submitForSelfGrading(attemptId, convertStemMarkschemeUIModelToGradedMarkscheme(gradableMarkscheme))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  setChatClosed() {
    this.activeItemId.set(null);
    this.isDesktopChatVisible.set(false);
  }

  toggleChatDialog(itemId: number) {
    if (this.activeItemId() === itemId && this.isDesktopChatVisible() && !this.isMobile) {
      this.setChatClosed();
    } else {
      this.activeItemId.set(itemId);
      if (this.platformService.width >= MOBILE_BREAKPOINT) {
        this.isDesktopChatVisible.set(true);
        if (this.platformService.width < DESKTOP_BREAKPOINT) {
          this.isLayoutTwoCols.set(false);
        }
      }
      this.openChatDialog(this.state.attempt?.status === 'GRADED' ? 'grading' : 'coach', itemId);
    }
  }

  toggleGradingResult(itemId: number) {
    if (this.openedGradingResultItemId() === itemId) {
      this.openedGradingResultItemId.set(null);
    } else {
      this.openedGradingResultItemId.set(itemId);
    }
  }

  goToNextQuestion() {
    this.isDesktopChatVisible.set(false);
    this.chatMessageService.resetChat();
    this.completed.emit('continue');
  }

  endPractice() {
    this.chatMessageService.resetChat();
    this.completed.emit('stop');
  }

  openChatDialog(phase: 'coach' | 'grading', stemId: number) {
    this.activeItemId.set(stemId);
    this.updateChatContext(phase, stemId);

    if (this.state.isMobile) {
      this.chatDialogService.openDialog();
    } else {
      this.isDesktopChatVisible.set(true);
    }
  }

  toggleColumns() {
    const newValue = !this.isLayoutTwoCols();
    this.isLayoutTwoCols.set(newValue);
  }

  openQuestionActionDialog() {
    from(this.assessmentActionDialogService.openDialog()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  private closeDesktopChatWhenGraded() {
    return this.questionAttemptStatus$.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.openedGradingResultItemId.set(null);
      }),
      map((questionAttemptStatus) => questionAttemptStatus === 'GRADED'),
      filter(Boolean),
      tap(() => {
        this.isDesktopChatVisible.set(false);
        this.chatMessageService.resetChat();
      }),
      debounceTime(250),
      tap(() => {
        const questionItems = this.state.questionItems;
        if (!questionItems) {
          return;
        }
        this.expandFirstFailedStemGradingResult(questionItems.filter((item) => item.type === 'stem'));
      }),
    );
  }

  private updateChatContext(phase: 'coach' | 'grading', stemId: number) {
    const questionAttemptChat = this.questionQuery.getValue().chat;

    this.chatMessageService.setCurrentChatContext({
      chatId:
        phase === 'coach'
          ? questionAttemptChat?.pre_grading_llm_chat_id ?? null
          : questionAttemptChat?.post_grading_llm_chat_id ?? null,
      engagementPhase: phase === 'coach' ? EngagementPhase.PreGrading : EngagementPhase.PostGrading,
      stemId,
    });
  }

  private expandFirstFailedStemGradingResult(stems: StemUIModel[]) {
    const firstFailedStem = stems.find((stem) => stem.gradingResult?.awardedMarks !== stem.totalMarks);
    if (firstFailedStem) {
      this.openedGradingResultItemId.set(firstFailedStem.id);
    }
  }

  private shouldOpenSolutionCaptureDialogOnDialogFlag() {
    return combineLatest([this.questionQuery.openCaptureDialog$, this.question$]).pipe(
      filter(([openCaptureDialog, question]) => !!openCaptureDialog && !!question),
      tap(([openCaptureDialogFlag]) => {
        if (openCaptureDialogFlag) {
          this.openSolutionCaptureDialog();
          this.questionService.setOpenCaptureDialogFlag(false);
        }
      }),
    );
  }

  private reactToResize() {
    return this.platformService.resize$.pipe(
      debounceTime(150),
      tap(({ width }) => {
        if (width < MOBILE_BREAKPOINT && !this.isMobile) {
          this.isLayoutTwoCols.set(false);
          this.setChatClosed();
        } else if (width >= MOBILE_BREAKPOINT && this.isTablet) {
          this.modalCtrl.dismiss(null, '', 'chat-dialog');
        }
        if (width < DESKTOP_BREAKPOINT && this.isDesktopChatVisible()) {
          this.isLayoutTwoCols.set(false);
        }
      }),
    );
  }
}
