@if (state.totalMarks !== null && state.awardedMarks !== null && state.pacePerMark !== null) {
  <ion-content>
    <div class="flex h-full flex-col">
      <div class="tiny-container my-auto">
        @if (!!state.screenDisplay) {
          <canvas
            #riveCanvas
            rive
            loading="lazy"
            class="loading-sensei mx-auto"
            [riveFile]="state.screenDisplay.sensei.riveFile"
            [artboard]="state.screenDisplay.sensei.artboard"
            [stateMachine]="'State Machine 1'"
            [autoplay]="true"
            width="1000"
            height="1000"
          ></canvas>
          <div class="stats-screen-title">{{ state.screenDisplay.title | transloco }}</div>
          <div class="stats-screen-subtitle">{{ state.screenDisplay.subtitle | transloco }}</div>
        }
        <div class="stats-wrapper mb-4" @listStaggerAnimation>
          <div class="stats-item top">
            <div class="stats-left-side">
              <dojo-image src="assets/images/post-activity/marks-earned.svg" width="55" />
            </div>
            <div class="stats-right-side">
              <div class="stats-header !mb-3">
                <div class="stats-header-title">{{ 'marks_earned' | transloco }}</div>
                <div class="stats-header-number text-green-500">{{ formattedMarks() }}/{{ state.totalMarks }}</div>
              </div>
              <dojo-marks-earned-bar
                class="w-full"
                [marksEarned]="state.awardedMarks"
                [totalMarks]="state.totalMarks"
              />
            </div>
          </div>
          <div class="stats-item bottom !pb-5">
            <div class="stats-left-side">
              <dojo-image src="assets/images/post-activity/time-spent.svg" width="55" />
            </div>
            <div class="stats-right-side">
              <div class="stats-header">
                <div class="stats-header-title">{{ 'grading.min_per_mark' | transloco }}</div>
                <div class="stats-header-number text-indigo-500">{{ formattedTime() }}</div>
              </div>
              <dojo-pace-boundary-bar #paceBoundaryBar class="w-full" [pace]="state.pacePerMark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
} @else {
  <div class="flex h-full w-full items-center justify-center">
    <dojo-loader />
  </div>
}

<ng-template #right>
  <dojo-button (click)="goToNextStep()" class="w-full">{{ 'grading.review_results' | transloco }}</dojo-button>
</ng-template>
