import { Injectable } from '@angular/core';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';

@Injectable()
export class ChatImageService {
  constructor() {}

  async takePicture(): Promise<null | Photo> {
    try {
      return await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });
    } catch (error) {
      return null;
    }
  }
}
