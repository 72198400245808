@if (state.isMobile) {
  <ion-header [translucent]="true" class="mobile-header">
    <ion-toolbar class="!px-0">
      <div class="small:mx-auto flex items-center justify-between px-4">
        <div slot="start" class="flex">
          <dojo-icon-button (click)="cancel()" [icon]="'xmark'" [iconSize]="26" [iconClass]="'text-neutral-500'" />
        </div>
        <ion-title class="common-small-ion-title">
          {{ 'topic_practice.training_adventure' | transloco }}
        </ion-title>
        <div slot="end" class="aligner flex h-10 w-10"></div>
      </div>
    </ion-toolbar>
  </ion-header>
}

<ion-content>
  <div class="flex h-full flex-col py-4 md:py-6">
    <div class="standard-container">
      <h3 class="text-primary-exam text-display-md mb-6 hidden font-medium md:block">
        {{ 'topic_practice.training_adventure' | transloco }}
      </h3>
      <dojo-sensei
        class="cards-sensei flex"
        [activeStoryCharacter]="activeStoryCharacter"
        [activeSpeechBubble]="state.activeSpeechBubble"
      ></dojo-sensei>
    </div>

    <div class="desktop-card-container standard-container pb-4">
      <div class="cards-wrap flex flex-wrap justify-center gap-6" [@listStaggerAnimation]>
        @for (
          questionCandidate of state.sortedQuestionCandidates;
          track questionCandidate.question_id;
          let i = $index
        ) {
          <div class="question-selection-card">
            <dojo-question-selection-card
              [questionCandidate]="questionCandidate"
              [isSelected]="selectedQuestionId() === questionCandidate.question_id"
              (click)="selectQuestionCard(questionCandidate.question_id)"
            />
          </div>
        }
      </div>
    </div>
  </div>
</ion-content>

<dojo-footer-panel
  [leftButtonLabel]="!state.isMobile ? 'quit' : undefined"
  [rightButtonLabel]="'continue'"
  [rightButtonDisabled]="!selectedQuestionId()"
  (rightButtonClicked)="submit()"
  (leftButtonClicked)="!state.isMobile ? cancel() : undefined"
>
</dojo-footer-panel>
