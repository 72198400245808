import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PracticeActivityQuery } from '@examdojo/practice-activity';
import { QuestionQuery, QuestionService, SolutionCaptureDialogService } from '@examdojo/question';
import { concatMap, distinctUntilChanged, filter, finalize, map, merge, Observable, of, switchMap } from 'rxjs';
import { QuestionAttemptRealtimeService } from '../../../../question-v2';

@Injectable()
export class CloseSolutionCaptureDialogEffectService {
  constructor(
    private readonly solutionImagesListDialogService: SolutionCaptureDialogService,
    private readonly questionQuery: QuestionQuery,
    private readonly questionService: QuestionService,
    private readonly practiceActivityQuery: PracticeActivityQuery,
    private readonly questionAttemptRealtimeService: QuestionAttemptRealtimeService,
  ) {
    merge(
      this.shouldCloseSolutionCaptureDialogOnAttemptStatusChange(),
      this.shouldCloseSolutionCaptureDialogOnPracticeAttemptStatusChange(),
      this.sendCustomDismissEventOnDismissOfSolutionCapture(),
    )
      .pipe(
        finalize(() => {
          if (this.solutionImagesListDialogService.isOpen()) {
            this.solutionImagesListDialogService.dismiss();
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly activePracticeActivityId$: Observable<string | undefined> =
    this.practiceActivityQuery.practiceActivities$.pipe(
      map((activity) => activity.filter((a) => a.status === 'NOT_STARTED' || a.status === 'IN_PROGRESS')),
      map((activity) => activity[0]?.id),
      distinctUntilChanged(),
    );

  shouldCloseSolutionCaptureDialogOnAttemptStatusChange() {
    return this.questionQuery.attemptStatus$.pipe(
      distinctUntilChanged(),
      filter((status) => !!status && status !== 'PENDING'),
      switchMap((status) => {
        if (status === 'GRADED' || status === 'FAILED') {
          this.questionService.setOpenCaptureDialogFlag(false);
          return this.solutionImagesListDialogService.dismiss();
        }
        return of();
      }),
    );
  }

  shouldCloseSolutionCaptureDialogOnPracticeAttemptStatusChange() {
    return this.activePracticeActivityId$.pipe(
      distinctUntilChanged(),
      switchMap((activePracticeActivityId) => {
        if (!activePracticeActivityId) {
          this.questionService.setOpenCaptureDialogFlag(false);
          return this.solutionImagesListDialogService.dismiss();
        }
        return of();
      }),
    );
  }

  private sendCustomDismissEventOnDismissOfSolutionCapture() {
    return merge(
      this.solutionImagesListDialogService.dismissed$.pipe(map(() => ({ dismissed: true }))),
      this.solutionImagesListDialogService.opened$.pipe(map(() => ({ opened: true }))),
    ).pipe(
      concatMap((event) => {
        if ('dismissed' in event && event.dismissed) {
          return this.questionAttemptRealtimeService.sendQuestionSolutionCaptureDialogEvent(false);
        }

        if ('opened' in event && event.opened) {
          return this.questionAttemptRealtimeService.sendQuestionSolutionCaptureDialogEvent(true);
        }

        return of();
      }),
    );
  }
}
