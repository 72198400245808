import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { BillingService, BillingStepperDialogService } from '@examdojo/billing';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { InternationalizationService } from '@examdojo/core/i18n';
import { mapToVoid } from '@examdojo/core/rxjs';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { QuestionAttemptHttpModel, QuestionQuery, QuestionService } from '@examdojo/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { RiveDirective } from '@examdojo/ui/rive';
import { IonContent } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import {
  combineLatest,
  concat,
  debounceTime,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  scan,
  switchMap,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { FullscreenFlowStepComponent } from '../../../../shared/fullscreen-flow';
import { FullscreenFlowDialogService } from '../../../../shared/fullscreen-flow/fullscreen-flow-dialog.service';

gsap.registerPlugin(SplitText);

@Component({
  selector: 'dojo-grading-loading',
  imports: [ButtonComponent, RiveDirective, TranslocoPipe, IonContent],
  templateUrl: './grading-loading.component.html',
  styleUrl: './grading-loading.component.scss',
  host: { class: 'ion-page' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradingLoadingComponent extends FullscreenFlowStepComponent {
  constructor(
    private readonly questionV2Query: QuestionQuery,
    private readonly questionV2Service: QuestionService,
    private readonly fullscreenFlowDialogService: FullscreenFlowDialogService,
    private readonly internalTranslocoService: InternationalizationService,
    private readonly billingService: BillingService,
    private readonly destroyRef: DestroyRef,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly billingStepperDialogService: BillingStepperDialogService,
    private readonly featureFlagService: FeatureFlagService<ExamdojoFeatureFlags>,
  ) {
    super();

    this.onStatus('GRADED')
      .pipe(
        // A small timeout to help render the Rive elements on the second screen.
        // Not sure what's causing the issues, they seem to be quite random,
        // maybe race conditions, but not sure what's causing them.
        debounceTime(50),
        switchMap(() => this.goToNextStep()),
        takeUntilDestroyed(),
      )
      .subscribe();
    this.createMessageAnimation().pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly messageText = viewChild<ElementRef<HTMLElement>>('messageText');
  private readonly messageElement$ = toObservable(this.messageText).pipe(
    filter(Boolean),
    map((ref) => ref.nativeElement),
  );

  private readonly TOTAL_DYNAMIC_MESSAGES = 8;
  private readonly TOTAL_STATIC_MESSAGES = 17;

  readonly state = connectState({
    gradingFailed: this.questionV2Query.attemptStatus$.pipe(map((status) => status === 'FAILED')),
    staticMessage: of(`grading.loading_messages.static.${Math.floor(Math.random() * this.TOTAL_STATIC_MESSAGES) + 1}`),
    gradingError: this.questionV2Query.gradingError$,
    finishedGrading: this.questionV2Query.attemptStatus$.pipe(map((status) => status !== 'SUBMITTED')),
    useBillingStepper: this.featureFlagService.select(ExamDojoFeatureFlag.Billing),
  });

  dismissDialog(shouldRecapture: boolean) {
    if (shouldRecapture) {
      this.questionV2Service.setOpenCaptureDialogFlag(true);
    }
    this.fullscreenFlowDialogService.dismiss();
  }

  openBillingDialog() {
    this.fullscreenFlowDialogService.dismiss();

    this.billingStepperDialogService.openDialog();
  }

  openProPlanCheckout() {
    this.billingService
      .fetchHostedCheckoutSessionUrl()
      .pipe(
        tap((checkoutUrl) => {
          window.location.href = checkoutUrl;
        }),
        this.errorHandlerService.catchError('[BillingComponent]: Failed to generate checkout session', () => EMPTY, {
          toast: 'Failed to open checkout session',
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private generateDynamicMessage(): Observable<string> {
    return concat(
      of(`grading.loading_messages.dynamic.1`),
      timer(0, Math.floor(Math.random() * 2000) + 3000).pipe(
        scan((currentIndex) => {
          return Math.min(currentIndex + 1, this.TOTAL_DYNAMIC_MESSAGES);
        }, 1),
        takeWhile((index) => index < this.TOTAL_DYNAMIC_MESSAGES, true),
        map((messageIndex) => `grading.loading_messages.dynamic.${messageIndex}`),
      ),
    );
  }

  private createMessageAnimation(): Observable<void> {
    return combineLatest([this.generateDynamicMessage(), this.messageElement$]).pipe(
      distinctUntilChanged(),
      switchMap(([message, element]) => {
        return defer(
          () =>
            new Observable<void>((subscriber) => {
              // Kill any existing animations
              gsap.killTweensOf(element);
              const dots = document.createElement('span');

              // Animate out current text
              gsap.to(element, {
                opacity: 0,
                y: -20,
                scale: 0.95,
                duration: 0.3,
                ease: 'power2.in',
                onComplete: () => {
                  // Update text content
                  element.textContent = this.internalTranslocoService.translate(message);
                  dots.style.width = '24px';
                  dots.style.display = 'inline-block';
                  dots.style.textAlign = 'left';
                  dots.textContent = '   '; // Three spaces initially
                  element.appendChild(dots);

                  // Animate dots
                  gsap.to(dots, {
                    duration: 2.5,
                    ease: 'steps(3)',
                    onUpdate: function (this: gsap.core.Tween) {
                      const progress = Math.floor(this['progress']() * 3);
                      dots.textContent = '.'.repeat(progress) + ' '.repeat(3 - progress);
                    },
                    onComplete: () => {
                      dots.textContent = '...';
                    },
                  });

                  // Animate in new text
                  gsap.fromTo(
                    element,
                    {
                      opacity: 0,
                      y: 20,
                      scale: 0.95,
                    },
                    {
                      opacity: 1,
                      y: 0,
                      scale: 1,
                      duration: 0.4,
                      ease: 'back.out(1.7)',
                      onComplete: () => {
                        subscriber.next();
                        subscriber.complete();
                      },
                    },
                  );
                },
              });
            }),
        );
      }),
    );
  }

  private onStatus(status: QuestionAttemptHttpModel['status']): Observable<void> {
    return this.questionV2Query.attemptStatus$.pipe(
      filter((attemptStatus) => attemptStatus === status),
      mapToVoid(),
    );
  }
}
