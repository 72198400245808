import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { ChatDialogComponent } from './chat-dialog.component';

@Injectable()
export class ChatDialogService {
  constructor(private readonly modalCtrl: ModalController) {}

  private readonly ID = 'chat-dialog';

  async openDialog() {
    const modal = await this.modalCtrl.create({
      component: ChatDialogComponent,
      componentProps: {},
      cssClass: 'modified-modal font-primary',
      id: this.ID,
    });

    await modal.present();

    return modal;
  }
}
