import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { IonSpinner } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { switchMap } from 'rxjs';
import { AgentLocalMessageUIModel } from '../../models';
import { ChatMessageQuery } from '../../store';

@Component({
  selector: 'dojo-agent-local-message',
  imports: [MarkdownViewerComponent, IonSpinner, TranslocoPipe],
  templateUrl: './agent-local-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentLocalMessageComponent {
  constructor(private readonly chatMessageQuery: ChatMessageQuery) {}

  readonly message = input.required<AgentLocalMessageUIModel>();

  readonly state = connectState({
    isLoading: toObservable(this.message).pipe(
      switchMap((message) => this.chatMessageQuery.selectIsLoading(message.id)),
    ),
  });
}
