import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TopicLevel1StoreModel, TopicLevel2StoreModel } from '@examdojo/category/v2';
import { LocalizedStringComponent, LocalizePipe } from '@examdojo/core/i18n';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { ProficiencyPair } from '@examdojo/practice-activity';

export interface QuestionSelectionTopicsListComponentItem {
  topicLevel2Id: string | number;
  proficiency: ProficiencyPair;
  topicLevel2: TopicLevel2StoreModel | null;
  topicLevel1: TopicLevel1StoreModel | null;
  icon: {
    name: Icon;
    color: string;
  };
}

@Component({
  selector: 'dojo-question-selection-topic-list',
  template: `
    @for (topic of filteredList().mainSection; track $index) {
      @if (topic) {
        <div class="flex items-center gap-2">
          @if (topic.icon) {
            <div
              class="flex h-[32px] w-[32px] shrink-0 items-center justify-center rounded-full"
              [ngClass]="['bg-dynamic-' + topic.icon.color + '-400']"
            >
              <dojo-icon [icon]="topic.icon.name" [ngClass]="['text-dynamic-' + topic.icon.color + '-50']" />
            </div>
          }
          @if (topic.topicLevel2?.short_name; as topicShortName) {
            <span class="text-secondary font-medium-exam"><dojo-localized-string [content]="topicShortName" /></span>
          }
        </div>
      }
    }
    @if (filteredList().moreSection.three.length > 0) {
      <div class="flex justify-end">
        @for (topic of filteredList().moreSection.three; track $index) {
          <div
            class="border-neutral-0 relative -ml-[10px] flex h-[34px] w-[34px] shrink-0 items-center justify-center rounded-full border"
            [ngClass]="['bg-dynamic-' + topic.icon.color + '-400']"
            [matTooltip]="topic.topicLevel2?.code + ' - ' + (topic.topicLevel2?.short_name ?? {} | localize | async)"
          >
            <dojo-icon [icon]="topic.icon.name" class="text-neutral-0" />
          </div>
        }
        @if (filteredList().moreSection.rest.length > 0) {
          <div
            class="border-neutral-0 text-neutral-0 relative -ml-[10px] flex h-[34px] w-[34px] shrink-0 items-center justify-center rounded-full border bg-neutral-400 text-sm font-bold"
          >
            +{{ filteredList().moreSection.rest.length }}
          </div>
        }
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col gap-3',
  },
  imports: [LocalizedStringComponent, IconComponent, NgClass, LocalizePipe, AsyncPipe, MatTooltipModule],
})
export class QuestionSelectionTopicsListComponent {
  readonly questionTopicList = input.required<QuestionSelectionTopicsListComponentItem[]>();

  readonly filteredList = computed(() => {
    const questionList = this.questionTopicList();

    if (questionList.length <= 2) {
      return {
        mainSection: questionList,
        moreSection: { three: [], rest: [] },
      };
    }

    return {
      mainSection: questionList.slice(0, 2),
      moreSection: {
        three: questionList.slice(2, 5),
        rest: questionList.slice(5),
      },
    };
  });
}
