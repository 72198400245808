import { Injectable } from '@angular/core';
import { EntityStore } from '@examdojo/state';
import { ChatMessageState, ChatMessageStoreModel, EngagementPhase } from '../models';

@Injectable({ providedIn: 'root' })
export class ChatMessageStore extends EntityStore<ChatMessageState, ChatMessageStoreModel, 'id'> {
  constructor() {
    super({
      idKey: 'id',
      name: 'chat-message',
      initialPropsState: {
        savedLocalMessages: {},
        engagementPhase: EngagementPhase.PreGrading,
        chatId: null,
        stemId: null,
      },
    });
  }
}
