import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNotNullish } from '@examdojo/util/nullish';
import { combineLatest, map, Observable, of } from 'rxjs';
import { FullscreenFlowStep } from '../../shared/fullscreen-flow';
import { ActivityCompletionComponent } from '../activities/post-activity/activity-completion/activity-completion.component';
import { GradingLoadingComponent } from '../activities/post-activity/grading-loading/grading-loading.component';

@Injectable()
export class PostActivityScreensService {
  constructor() {}

  /**
   * Show the streak goal after every second practice until the user picks a daily marks goal
   */
  // private readonly shouldShowStreakGoal$ = this.userQuery.active$.pipe(
  //   filter(Boolean),
  //   map((user) => {
  //     // TODO: increment this value when the user completes a practice
  //     const rawPracticeCount = parseInt(localStorage.getItem('practice_count') ?? '', 10);
  //     const practiceCount = isNaN(rawPracticeCount) ? 0 : rawPracticeCount;
  //     return !user.streak_goal && practiceCount % 2 === 0;
  //   }),
  // );

  // private readonly streakGoalStep$ = this.shouldShowStreakGoal$.pipe(
  //   map((show) => {
  //     if (!show) {
  //       return null;
  //     }

  //     const step: FullscreenFlowStep<FormGroup<FormControlsOf<StreakGoalFormModel>>> = {
  //       component: StreakGoalComponent,
  //       form: new FormGroup({
  //         streaksNumber: new FormControl(0, {
  //           nonNullable: true,
  //           validators: [Validators.required, Validators.min(1)],
  //         }),
  //       }),
  //       leftButton: {
  //         labelKey: 'examdojo.skip',
  //         disableStepControl: true,
  //         onClick: (_, stepper) => {
  //           stepper.goNextOrSubmit({ force: true });
  //         },
  //       },
  //       rightButton: {
  //         onClick: (formValue) => {
  //           const userId = this.userQuery.getActiveId()!;
  //           this.userService.update(userId, { streak_goal: formValue.streaksNumber });
  //         },
  //       },
  //     };

  //     return step;
  //   }),
  // );

  readonly postActivityScreens$: Observable<Array<FullscreenFlowStep<FormGroup>>> = combineLatest([
    of({
      component: GradingLoadingComponent,
      rightButton: {
        // A hack to have the next button rendered but not visible
        // Otherwise the next() functionality of the swiper will not work
        class: 'invisible select-none',
      },
    }),

    of({
      component: ActivityCompletionComponent,
    }),
    // of({ component: StreakComponent }),
    // this.streakGoalStep$,
  ]).pipe(map((candidateSteps) => candidateSteps.filter(isNotNullish)));
}
