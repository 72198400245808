import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dojo-question-selection-proficiency-bar',
  template: `
    <div class="flex h-3 gap-0.5 rounded-full">
      <div
        class="h-full rounded-s-full bg-amber-400"
        [ngStyle]="{
          width: priorScore * 100 + '%'
        }"
      ></div>
      <div
        class="h-full bg-green-400"
        [ngStyle]="{
          width: (predictedScore - priorScore) * 100 + '%'
        }"
      ></div>
      <div
        class="h-full rounded-e-full bg-neutral-100"
        [ngStyle]="{
          width: 100 - priorScore * 100 - (predictedScore - priorScore) * 100 + '%'
        }"
      ></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle],
})
export class QuestionSelectionProficiencyBarComponent {
  @Input({ required: true }) predictedScore!: number;
  @Input({ required: true }) priorScore!: number;
}
