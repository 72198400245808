import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { IfLocalizedStringDirective } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';
import { MarkschemePointComponent } from '@examdojo/markscheme';
import { GradedMark, SelfGradableMarkGroupAlternative } from '@examdojo/question';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'dojo-question-grading-method-result-row',
  template: `
    <div class="flex w-full border-t border-neutral-200">
      <div class="left-cell text-neutral-1000 w-[70%] border-r border-neutral-200 p-4">
        @if (showName()) {
          <h3 *dojoIfLocalizedString="markGroupAlternative().name; let name" class="text-md mb-2 font-semibold">
            {{ name }}
          </h3>
        }

        @for (mark of markGroupAlternative().marks; track $index) {
          <dojo-markdown-viewer *dojoIfLocalizedString="mark.text; let text" [content]="text" />
        }
      </div>
      <div class="right-cell font-medium-exam w-[30%] p-4 text-sm">
        @if (markGroupAlternativeMarks(); as markGroupAlternativeMarks) {
          <div class="flex flex-wrap items-center gap-2">
            @for (mark of markGroupAlternative().marks; track $index; let markIndex = $index) {
              @for (point of mark.points; track $index; let pointIndex = $index) {
                <span
                  class="flex items-center gap-0.5"
                  [ngClass]="{
                    'text-green-600':
                      markGroupAlternativeMarks[markIndex].points[pointIndex].awarded_amount ===
                      markGroupAlternativeMarks[markIndex].points[pointIndex].amount,
                    'text-red-600':
                      markGroupAlternativeMarks[markIndex].points[pointIndex].awarded_amount <
                      markGroupAlternativeMarks[markIndex].points[pointIndex].amount
                  }"
                >
                  @if (
                    markGroupAlternativeMarks[markIndex].points[pointIndex].awarded_amount <
                    markGroupAlternativeMarks[markIndex].points[pointIndex].amount
                  ) {
                    <dojo-icon [icon]="'xmark'"></dojo-icon>
                  } @else {
                    <dojo-icon [icon]="'check'"></dojo-icon>
                  }
                  <dojo-markscheme-point [point]="point" />
                </span>
              }
            }
          </div>
          <div class="flex flex-col">
            @for (mark of markGroupAlternative().marks; track $index; let markIndex = $index) {
              @if (markGroupAlternativeMarks[markIndex] && markGroupAlternativeMarks[markIndex].mark_level_feedback) {
                <dojo-markdown-viewer
                  [applyProseClass]="false"
                  [ngClass]="{
                    'text-green-600':
                      markGroupAlternativeMarks[markIndex] &&
                      markGroupAlternativeMarks[markIndex].totalAwardedPoints ===
                        markGroupAlternativeMarks[markIndex].totalMaxPoints,
                    'text-red-600':
                      markGroupAlternativeMarks[markIndex] &&
                      markGroupAlternativeMarks[markIndex].totalAwardedPoints !==
                        markGroupAlternativeMarks[markIndex].totalMaxPoints
                  }"
                  [content]="markGroupAlternativeMarks[markIndex].mark_level_feedback"
                />
              }
            }
          </div>
        } @else if (!selfGradingAllowed()) {
          <div class="text-disabled flex items-center gap-2">
            @for (mark of markGroupAlternative().marks; track $index; let markIndex = $index) {
              @for (point of mark.points; track $index; let pointIndex = $index) {
                <dojo-markscheme-point [point]="point" />
              }
            }
          </div>
          <span class="text-disabled">{{ 'grading.not_applicable' | transloco }}</span>
        } @else {
          <!-- self grading result row -->
          <div class="text-disabled flex flex-wrap items-center gap-2">
            @for (mark of markGroupAlternative().marks; track $index; let markIndex = $index) {
              @for (point of mark.points; track $index; let pointIndex = $index) {
                <mat-checkbox color="primary" class="mb-1 mt-1" [formControl]="point.formCtrl">
                  <dojo-markscheme-point [point]="point" />
                </mat-checkbox>
              }
            }
          </div>
        }
      </div>
    </div>
  `,
  imports: [
    MarkdownViewerComponent,
    NgClass,
    IfLocalizedStringDirective,
    MarkschemePointComponent,
    ReactiveFormsModule,
    TranslocoPipe,
    IconComponent,
    MatCheckbox,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionGradingMethodResultRowComponent {
  readonly markGroupAlternative = input.required<SelfGradableMarkGroupAlternative>();
  readonly markGroupAlternativeMarks = input.required<GradedMark[] | null>();
  readonly showName = input.required<boolean>();
  readonly showMarks = input.required<boolean>();
  readonly selfGradingAllowed = input<boolean>();
}
