import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { SyllabusQuery } from '@examdojo/category';
import { LocalizeService } from '@examdojo/core/i18n';
import { PlatformService } from '@examdojo/platform';
import { QuestionFlagFeedbackDirective, QuestionQuery } from '@examdojo/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { switchMap, tap } from 'rxjs';
import { SMALL_SCREEN_WIDTH } from '../../app.model';
import { QUESTION_ACTION_DIALOG_ID } from './question-action-dialog.service';

@Component({
  selector: 'dojo-question-action-dialog',
  imports: [ButtonComponent, TranslocoPipe, QuestionFlagFeedbackDirective],
  templateUrl: './question-action-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionActionDialogComponent {
  constructor(
    private readonly questionQuery: QuestionQuery,
    private readonly modalController: ModalController,
    private readonly destroyRef: DestroyRef,
    private readonly platformService: PlatformService,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly localizeService: LocalizeService,
  ) {
    this.reactToResize().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  readonly state = connectState({
    isMobile: this.platformService.isSmall$,
    formulaBooklet: this.syllabusQuery.active$.pipe(
      switchMap((syllabus) => this.localizeService.localize(syllabus?.formular_booklet ?? {})),
    ),
    questionFlagged: this.questionQuery.questionFlagged$,
  });

  private reactToResize() {
    return this.platformService.resize$.pipe(
      tap(() => {
        if (this.platformService.width >= SMALL_SCREEN_WIDTH) {
          this.modalController.dismiss(null, '', QUESTION_ACTION_DIALOG_ID);
        }
      }),
    );
  }
}
