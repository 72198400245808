import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { ChatMessageModel } from '@examdojo/models/chat';
import { QuestionAttemptHttpModel } from '@examdojo/question';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatMessageHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly supabase: ExamdojoSupabaseService,
  ) {}

  private readonly baseUrl = `${environment.examdojo.examdojoApiUrl}/question-attempts`;

  fetchResponseMessage(
    payload: {
      message: string;
      phase: string;
      images: string[];
      stem_id: number;
    },
    questionAttemptId: QuestionAttemptHttpModel['id'],
  ): Observable<HttpEvent<string>> {
    return this.http.post(this.url(questionAttemptId), payload, {
      responseType: 'text',
      observe: 'events',
      reportProgress: true,
    });
  }

  fetchAllByChatIdAndMetadata(llmChatId: number, stemId: number): Observable<ChatMessageModel[]> {
    return from(
      this.supabase.client
        .from('llm_chat_messages')
        .select()
        .eq('llm_chat_id', llmChatId)
        .eq('metadata ->> stem_id', String(stemId))
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  private url(questionAttemptId: QuestionAttemptHttpModel['id']): string {
    return `${this.baseUrl}/${questionAttemptId}/chat/messages`;
  }
}
