import { ChangeDetectionStrategy, Component, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { listStaggerAnimation } from '@examdojo/animation';
import { MarksEarnedBarComponent, PaceBoundaryBarComponent } from '@examdojo/category/v2';
import { DateTimeService } from '@examdojo/core/date-time';
import { QuestionQuery } from '@examdojo/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { ImageComponent } from '@examdojo/ui/image';
import { LoaderComponent } from '@examdojo/ui/loader';
import { RiveDirective } from '@examdojo/ui/rive';
import { IonContent } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { gsap } from 'gsap';
import JSConfetti from 'js-confetti';
import { combineLatest, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { FullscreenFlowStepComponent } from '../../../../shared/fullscreen-flow';

@Component({
  selector: 'dojo-activity-completion',
  imports: [
    LoaderComponent,
    ImageComponent,
    TranslocoPipe,
    RiveDirective,
    PaceBoundaryBarComponent,
    MarksEarnedBarComponent,
    ButtonComponent,
    IonContent,
  ],
  templateUrl: './activity-completion.component.html',
  styleUrl: './activity-completion.component.scss',
  host: { class: 'ion-page' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listStaggerAnimation('.stats-item')],
})
export class ActivityCompletionComponent extends FullscreenFlowStepComponent {
  constructor(
    private readonly questionQuery: QuestionQuery,
    private readonly dateTimeService: DateTimeService,
  ) {
    super();

    // Set up observer to animate marks when they change
    this.questionQuery.questionGradingAwardedPoints$
      .pipe(
        filter(Boolean),
        tap((marks: number) => this.animateMarks(marks)),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.questionQuery.timeSpent$
      .pipe(
        filter(Boolean),
        tap((timeSpent: number) => this.animateTime(timeSpent)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  private readonly jsConfetti = new JSConfetti();

  // anmation
  private readonly animatedMarks = signal(0);
  private readonly animatedTimeMs = signal(0);

  private readonly paceBoundaryBar = viewChild<PaceBoundaryBarComponent>('paceBoundaryBar');
  private readonly paceBoundaryBar$ = toObservable(this.paceBoundaryBar).pipe(
    filter((component): component is PaceBoundaryBarComponent => component !== null),
  );

  private readonly pacePerMark$ = combineLatest([
    this.questionQuery.timeSpent$,
    this.questionQuery.questionGradingTotalPoints$,
  ]).pipe(
    filter(([pace, totalMarks]) => pace !== null && totalMarks !== null),
    map(([pace, totalMarks]) => Number(((pace ?? 0) / totalMarks!).toFixed(0))),
    distinctUntilChanged(),
  );

  readonly formattedMarks = signal('0');
  readonly formattedTime = signal('0s');

  readonly state = connectState({
    awardedMarks: this.questionQuery.questionGradingAwardedPoints$,
    totalMarks: this.questionQuery.questionGradingTotalPoints$,
    pacePerMark: this.pacePerMark$,
    screenDisplay: combineLatest([
      this.questionQuery.questionGradingAwardedPoints$,
      this.questionQuery.questionGradingTotalPoints$,
      this.paceBoundaryBar$.pipe(
        filter((component): component is PaceBoundaryBarComponent => !!component),
        map((component) => component.chartPaceIndex().chartIndex),
      ),
    ]).pipe(
      filter(([awarded, total, pace]) => awarded !== null && total !== null && pace !== null),
      tap(() => setTimeout(() => this.triggerConfetti(), 500)),
      map(([awarded, total, pace]) => {
        const marksLevel = this.getMarksLevel(awarded!, total!);
        const paceLevel = this.getPaceLevel(pace!);
        const feedback = this.getFeedback(marksLevel, paceLevel);
        const sensei = this.getSenseiRiveFile(marksLevel, paceLevel);

        return {
          title: feedback.title,
          subtitle: feedback.subtitle,
          sensei: sensei,
        };
      }),
    ),
  });

  private triggerConfetti() {
    this.jsConfetti.addConfetti({
      emojis: ['🎉', '⚡️', '💥', '✨', '🥳', '💪'],
      emojiSize: 70,
      confettiNumber: 50,
    });
  }

  private animateMarks(targetMarks: number): void {
    // Reset to 0 if we're reanimating
    this.animatedMarks.set(0);
    this.formattedMarks.set('0');

    const obj = { value: 0 };

    gsap.to(obj, {
      value: targetMarks,
      duration: 0.5,
      ease: 'power1.in',
      snap: { value: 1 },
      onUpdate: () => {
        const value = Math.ceil(obj.value);
        this.animatedMarks.set(value);
        this.formattedMarks.set(this.numberWithCommas(value));
      },
    });
  }

  private numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private getSenseiRiveFile(
    marksLevel: 'LOW' | 'MEDIUM' | 'HIGH',
    paceLevel: 'SLOW' | 'PERFECT' | 'FAST',
  ): { riveFile: string; artboard: string } {
    const fileMap = {
      LOW: {
        SLOW: { riveFile: 'assets/images/post-activity/grade/low_marks_slow_pace.riv', artboard: 'Artboard' },
        PERFECT: { riveFile: 'assets/images/post-activity/grade/low_marks_perfect_pace.riv', artboard: 'Artboard' },
        FAST: { riveFile: 'assets/images/post-activity/grade/low_marks_fast_pace.riv', artboard: 'Artboard' },
      },
      MEDIUM: {
        SLOW: { riveFile: 'assets/images/post-activity/grade/medium_marks_slow_pace.riv', artboard: 'Artboard' },
        PERFECT: { riveFile: 'assets/images/post-activity/grade/medium_marks_perfect_pace.riv', artboard: 'Artboard' },
        FAST: { riveFile: 'assets/images/post-activity/grade/medium_marks_fast_pace.riv', artboard: 'Artboard' },
      },
      HIGH: {
        SLOW: { riveFile: 'assets/images/post-activity/grade/high_marks_low_pace.riv', artboard: 'Artboard' },
        PERFECT: { riveFile: 'assets/images/post-activity/grade/high_marks_perfect_pace.riv', artboard: 'Artboard' },
        FAST: { riveFile: 'assets/images/post-activity/grade/high_marks_fast_pace.riv', artboard: 'Artboard' },
      },
    };

    return fileMap[marksLevel][paceLevel];
  }

  private getMarksLevel(awarded: number, total: number): 'LOW' | 'MEDIUM' | 'HIGH' {
    const percentage = (awarded / total) * 100;
    if (percentage <= 33) {
      return 'LOW';
    }
    if (percentage <= 66) {
      return 'MEDIUM';
    }
    return 'HIGH';
  }

  private getFeedback(
    marksLevel: 'LOW' | 'MEDIUM' | 'HIGH',
    paceLevel: 'SLOW' | 'PERFECT' | 'FAST',
  ): { title: string; subtitle: string } {
    const level = marksLevel.toLowerCase();
    const pace = paceLevel.toLowerCase();
    return {
      title: `grading.feedback.${level}.${pace}.title`,
      subtitle: `grading.feedback.${level}.${pace}.subtitle`,
    };
  }

  private getPaceLevel(pace: number): 'SLOW' | 'PERFECT' | 'FAST' {
    if (pace === 0) {
      return 'SLOW';
    }
    if (pace === 1) {
      return 'PERFECT';
    }
    return 'FAST';
  }

  private animateTime(targetTimeMs: number): void {
    // Reset to 0 if we're reanimating
    this.animatedTimeMs.set(0);
    this.formattedTime.set('0s');

    // Convert to seconds to reduce updates
    const targetTimeSec = targetTimeMs / 1000;
    const obj = { value: 0 };

    gsap.to(obj, {
      value: targetTimeSec,
      duration: 0.5,
      ease: 'power1.in',
      snap: { value: 1 }, // Snap to whole seconds
      onUpdate: () => {
        const valueInSeconds = Math.ceil(obj.value);
        this.animatedTimeMs.set(valueInSeconds * 1000); // Still store in ms for internal use
        this.formattedTime.set(
          this.dateTimeService.formatDurationShort(valueInSeconds, {
            unit: 'seconds',
            granularity: 2,
          }),
        );
      },
    });
  }
}
