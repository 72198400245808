<div class="flex w-full max-w-[90%]">
  <img src="assets/images/chat-logo.svg" class="w-[30px] self-start pt-[6px]" alt="{{ 'ask_question' | transloco }}" />
  <div class="flex flex-col px-3 py-2">
    <dojo-markdown-viewer
      [content]="message().message.text"
      class="font-normal-exam text-md text-primary-exam overflow-x-auto overflow-y-hidden"
    />

    @if (state.isLoading) {
      <ion-spinner name="crescent" class="mt-1 h-4 w-4" color="dark" />
    }
  </div>
</div>
