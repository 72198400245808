import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { getUserPromptNameFromMessage, UserMessageUIModel } from '../../models';
import { ChatImagesListItemComponent } from '../chat-images-list-item/chat-images-list-item.component';

@Component({
  selector: 'dojo-agent-user-message',
  host: { class: 'flex justify-end' },
  template: `
    <div class="flex max-w-[80%] flex-col items-end">
      @if (message().message.images.length) {
        <div class="flex justify-end gap-2 p-2">
          @for (image of message().message.images; track $index) {
            <dojo-chat-images-list-item [base64String]="image" [class]="'h-18 w-18'" [editable]="false" />
          }
        </div>
      }
      <dojo-markdown-viewer
        [content]="messageOrAlias()"
        class="font-normal-exam text-md text-primary-exam rounded-[12px] bg-stone-100 p-3"
      />
    </div>
  `,
  imports: [MarkdownViewerComponent, ChatImagesListItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMessageComponent {
  readonly message = input.required<UserMessageUIModel>();

  readonly messageOrAlias = computed(() => {
    const message = this.message().message.text;
    return getUserPromptNameFromMessage(message) ?? message;
  });
}
