import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@examdojo/platform';
import {
  QuestionAttemptHttpModel,
  QuestionQuery,
  QuestionService,
  SolutionCaptureDialogService,
} from '@examdojo/question';
import { RealtimeChangesListenEvent, RealtimeService } from '@examdojo/supabase';
import isEqual from 'lodash/isEqual';
import {
  BehaviorSubject,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  merge,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { FullscreenFlowService } from '../shared/fullscreen-flow';

enum QuestionAttemptEvent {
  DISMISS_DIALOG = 'DISMISS_DIALOG',
  SOLUTION_CAPTURE_DIALOG = 'SOLUTION_CAPTURE_DIALOG',
}

interface QuestionAttemptEventPayload {
  dismiss?: boolean;
  open?: boolean;
}

@Injectable()
export class QuestionAttemptRealtimeService {
  constructor(
    private readonly realtimeService: RealtimeService,
    private readonly questionQuery: QuestionQuery,
    private readonly service: QuestionService,
    private readonly fullscreenFlowService: FullscreenFlowService,
    private readonly solutionCaptureDialogService: SolutionCaptureDialogService,
    private readonly platformService: PlatformService,
  ) {
    merge(this.listenForQuestionAttemptStatusChange(), this.listenForQuestionAttemptDismissDialogEvent())
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  latestChannelMessageForSolutionCaptureEvent$$ = new BehaviorSubject<QuestionAttemptEventPayload | null>(null);

  listenForQuestionAttemptStatusChange() {
    return this.questionQuery.attempt$.pipe(
      map((attempt) => attempt?.id),
      filter(Boolean),
      distinctUntilChanged(),
      switchMap((attemptId) =>
        this.realtimeService
          .listenBroadcastRealtime<QuestionAttemptHttpModel>(
            [{ event: RealtimeChangesListenEvent.UPDATE }],
            `question_attempt_status:${attemptId}`,
          )
          .pipe(
            withLatestFrom(this.platformService.activeStatusState$),
            filter(([_, isActive]) => isActive),
            map(([event]) => event),
            concatMap((payload) => {
              if (
                payload.event === RealtimeChangesListenEvent.UPDATE &&
                payload?.payload?.record?.id &&
                payload.payload.record.status === 'SUBMITTED' &&
                this.questionQuery.getValue().attempt?.status !== 'SUBMITTED'
              ) {
                return this.handleSubmittedQuestionAttemptStatusChange(payload.payload.record);
              }

              if (
                payload.event === RealtimeChangesListenEvent.UPDATE &&
                payload?.payload?.record?.id &&
                payload.payload.record.status === 'FAILED' &&
                this.questionQuery.getValue().attempt?.status !== 'FAILED'
              ) {
                return this.handleFailedQuestionAttemptStatusChange(payload.payload.record);
              }

              if (
                payload.event === RealtimeChangesListenEvent.UPDATE &&
                payload?.payload?.record?.id &&
                payload.payload.record.status === 'GRADED' &&
                this.questionQuery.getValue().attempt?.status !== 'GRADED'
              ) {
                return this.handleGradedQuestionAttemptStatusChange(payload.payload.record);
              }

              return of();
            }),
          ),
      ),
    );
  }

  listenForQuestionAttemptDismissDialogEvent() {
    return this.questionQuery.attempt$.pipe(
      map((attempt) => attempt?.id),
      filter(Boolean),
      distinctUntilChanged(),
      switchMap((attemptId) =>
        this.realtimeService
          .listenBroadcastRealtime<QuestionAttemptEventPayload, QuestionAttemptEvent>(
            [{ event: QuestionAttemptEvent.DISMISS_DIALOG }, { event: QuestionAttemptEvent.SOLUTION_CAPTURE_DIALOG }],
            `question_attempt_event:${attemptId}`,
          )
          .pipe(
            withLatestFrom(this.platformService.activeStatusState$),
            filter(([_, isActive]) => isActive),
            map(([event]) => event),
            concatMap((payload) => {
              if (
                payload.event === QuestionAttemptEvent.DISMISS_DIALOG &&
                payload?.payload?.dismiss &&
                this.fullscreenFlowService.isOpen()
              ) {
                return this.fullscreenFlowService.dismiss();
              }

              if (payload.event === QuestionAttemptEvent.SOLUTION_CAPTURE_DIALOG) {
                this.latestChannelMessageForSolutionCaptureEvent$$.next(payload.payload);

                if (payload?.payload?.dismiss && this.solutionCaptureDialogService.isOpen()) {
                  this.latestChannelMessageForSolutionCaptureEvent$$.next(payload.payload);
                  return this.solutionCaptureDialogService.dismiss();
                }

                if (payload?.payload?.open && !this.solutionCaptureDialogService.isOpen()) {
                  this.service.setOpenCaptureDialogFlag(true);
                  return of();
                }
              }

              return of();
            }),
          ),
      ),
    );
  }

  sendQuestionDismissDialogEvent() {
    const channelName = `question_attempt_event:${this.questionQuery.getValue().attempt?.id}`;
    const event = QuestionAttemptEvent.DISMISS_DIALOG;
    const payload: QuestionAttemptEventPayload = { dismiss: true };

    return this.realtimeService.sendMessage(channelName, event, payload);
  }

  sendQuestionSolutionCaptureDialogEvent(open: boolean) {
    const channelName = `question_attempt_event:${this.questionQuery.getValue().attempt?.id}`;
    const event = QuestionAttemptEvent.SOLUTION_CAPTURE_DIALOG;
    const payload: QuestionAttemptEventPayload = open ? { open: true } : { dismiss: true };

    if (isEqual(this.latestChannelMessageForSolutionCaptureEvent$$.value, payload)) {
      return of();
    }

    return this.realtimeService.sendMessage(channelName, event, payload);
  }

  private handleGradedQuestionAttemptStatusChange(questionAttemptHttpModel: QuestionAttemptHttpModel) {
    return this.service.synchronizePostGradingState(questionAttemptHttpModel);
  }
  private handleSubmittedQuestionAttemptStatusChange(questionAttemptHttpModel: QuestionAttemptHttpModel) {
    this.service.setLocalAttempt(questionAttemptHttpModel);

    return of();
  }

  private handleFailedQuestionAttemptStatusChange(questionAttemptHttpModel: QuestionAttemptHttpModel) {
    this.service.setLocalAttempt(questionAttemptHttpModel);
    return of();
  }
}
