@if (!state.isMobile) {
  <as-split
    [direction]="'horizontal'"
    [unit]="'pixel'"
    [gutterSize]="DRAG_TARGET"
    [useTransition]="true"
    [gutterDblClickDuration]="300"
    [disabled]="!isDesktopChatVisible()"
  >
    <as-split-area [size]="sizeWildcard" class="!overflow-y-hidden">
      <div class="flex h-full flex-col justify-between pt-4">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </as-split-area>

    <as-split-area
      [size]="isDesktopChatVisible() ? INITIAL_LEFT_NAV_WIDTH_PX : 0"
      [minSize]="MIN_LEFT_NAV_WIDTH_PX"
      [maxSize]="MAX_LEFT_NAV_WIDTH_PX"
    >
      @if (isDesktopChatVisible()) {
        <dojo-chat-dialog [class.is-desktop]="!isMobile" class="chat-component" (closed)="setChatClosed()" />
      }
    </as-split-area>
  </as-split>
} @else {
  <ng-container *ngTemplateOutlet="content"></ng-container>
}

<ng-template #content>
  @if (state.isMobile) {
    <ion-header [translucent]="true" class="mobile-header">
      <ion-toolbar class="!px-0">
        <div class="small:mx-auto flex items-center justify-between px-4">
          <div slot="start" class="flex">
            <dojo-icon-button
              (click)="endPractice()"
              [icon]="'xmark'"
              [iconSize]="26"
              [iconClass]="'text-neutral-500'"
            />
          </div>
          <ion-title class="common-small-ion-title">
            @if (state.totalMarks && state.awardedMarks && state.timeSpentAmount) {
              <ng-container *ngTemplateOutlet="awardedMarksBlock"></ng-container>
            } @else {
              <ng-container *ngTemplateOutlet="difficultyBlock"></ng-container>
            }
          </ion-title>
          <div slot="end" class="aligner flex h-10 w-10">
            <dojo-icon-button
              (click)="openQuestionActionDialog()"
              [icon]="'ellipsis-vertical'"
              [iconSize]="22"
              [iconClass]="'text-neutral-500'"
            />
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
  }

  <ion-content [fullscreen]="true">
    <div class="standard-container">
      <div class="small:items-center small:flex-row flex flex-col flex-wrap justify-between gap-4 px-3 pb-6 pt-6">
        @if (state.totalMarks && state.awardedMarks && state.timeSpentAmount) {
          @if (!state.isMobile) {
            <ng-container *ngTemplateOutlet="awardedMarksBlock"></ng-container>
          }
        } @else {
          <div class="font-primary text-black-pure text-md md:text-lg">[Maximum mark: {{ state.totalMarks }}]</div>
        }
        @if (!state.isMobile) {
          <ng-container *ngTemplateOutlet="difficultyBlock"></ng-container>
        }
      </div>

      <div [class.two-column-layout]="isLayoutTwoCols()">
        <div class="stem-wrap font-primary text-black-pure flex flex-col text-lg">
          @for (item of state.questionItems; track $index) {
            @if (item.type === 'stimulus') {
              <div class="stimulus px-3 py-4">
                @if (item.stimulus | localize | async; as content) {
                  <dojo-markdown-viewer [content]="content" />
                }
              </div>
            }

            @if (item.type === 'question_image' && item.questionImageUrl) {
              <div class="question-image-container" @appearDisappear>
                <div class="group relative" dojoImagePreview [src]="item.questionImageUrl">
                  <div
                    class="absolute right-2 top-2 flex rounded-[0.5rem] bg-neutral-100 p-2 text-neutral-700 md:hidden md:group-hover:flex"
                  >
                    <dojo-icon
                      [icon]="'arrow-up-right-and-arrow-down-left-from-center'"
                      [matTooltip]="'enlarge' | transloco"
                    />
                  </div>
                  <ion-img [alt]="item.description" [src]="item.questionImageUrl" class="question-image" />
                </div>
              </div>
            }

            @if (item.type === 'stem') {
              <div
                class="stem group relative flex flex-col rounded-[1rem] px-3 py-4"
                role="button"
                tabindex="0"
                [class.!bg-neutral-50]="activeItemId() === item.id && isDesktopChatVisible()"
                [class.grading-result]="item.gradingResult || state.selfGradingAllowed"
              >
                <div
                  class="stem-inner small:flex-row small:gap-6 relative flex flex-col justify-between gap-3 pl-10"
                  (click)="(item.gradingResult || state.selfGradingAllowed) && toggleGradingResult(item.id)"
                  [class.opened]="openedGradingResultItemId() === item.id"
                >
                  <div class="stem-order-tag absolute left-0 top-0">{{ item.label | lowercase }})</div>
                  <ol class="substem-wrap small:self-center flex min-h-[30px] flex-col gap-2">
                    @for (subStem of item.stem; track $index) {
                      <li class="substeam">
                        <div class="substem-inner">
                          @if (subStem | localize | async; as content) {
                            <dojo-markdown-viewer [content]="content" />
                          }
                        </div>
                      </li>
                    }
                  </ol>
                  <div
                    class="flex min-h-[30px] items-center justify-between gap-4 self-start"
                    [class.grading-result-marks]="item.gradingResult || state.selfGradingAllowed"
                  >
                    <div class="small:group-focus-within:flex flex gap-2">
                      @if (item.gradingResult) {
                        <div class="flex items-center gap-4">
                          <span
                            class="text-md font-medium-exam ml-1"
                            [ngClass]="{
                              'text-green-600': item.gradingResult.awardedMarks === item.totalMarks,
                              'text-amber-600':
                                item.gradingResult.awardedMarks / item.totalMarks > 0.5 &&
                                item.gradingResult.awardedMarks / item.totalMarks < 1,
                              'text-red-600': item.gradingResult.awardedMarks / item.totalMarks <= 0.5
                            }"
                          >
                            [{{ item.gradingResult.awardedMarks }}/{{ item.totalMarks }}]</span
                          >
                          <div class="flex items-center justify-center text-neutral-300">
                            @if (openedGradingResultItemId() === item.id) {
                              <dojo-icon size="14" icon="chevron-up" />
                            } @else {
                              <dojo-icon size="14" icon="chevron-down" />
                            }
                          </div>
                        </div>
                      } @else if (!state.selfGradingAllowed) {
                        <span>[{{ item.totalMarks }}]</span>

                        @if (state.attemptStatus !== 'GRADED') {
                          <dojo-button
                            class="relative top-[-7px]"
                            [customHeight]="27"
                            [customWidth]="67"
                            [fill]="'outline'"
                            [customColor]="'sky'"
                            (click)="toggleChatDialog(item.id)"
                          >
                            <dojo-icon size="14" icon="sparkles"></dojo-icon>
                            <span class="ml-1 text-[14px]">{{ state.buttonText | transloco }}</span>
                          </dojo-button>
                        }
                      } @else {
                        <!-- self grading row -->
                        <span>[{{ item.totalMarks }}]</span>
                        <div class="flex items-center justify-center text-neutral-300">
                          @if (openedGradingResultItemId() === item.id) {
                            <dojo-icon size="14" icon="chevron-up" />
                          } @else {
                            <dojo-icon size="14" icon="chevron-down" />
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>

                @if (state.selfGradingAllowed && openedGradingResultItemId() === item.id && item.markscheme) {
                  <dojo-question-grading-result
                    [stemMarkscheme]="item.markscheme"
                    [selfGradingAllowed]="true"
                    (openChat)="toggleChatDialog(item.id)"
                    @appearDisappearWithHeight
                  />
                } @else if (item.markscheme && item.gradingResult && openedGradingResultItemId() === item.id) {
                  <dojo-question-grading-result
                    [gradedStemResult]="item.gradingResult"
                    [stemMarkscheme]="item.markscheme"
                    (openChat)="toggleChatDialog(item.id)"
                    @appearDisappearWithHeight
                  />
                }
              </div>
            }
          }
        </div>
      </div>
    </div>
  </ion-content>
</ng-template>

@if (state.isMobile) {
  <dojo-footer-panel>
    <div class="right item-center flex flex-row justify-end gap-2">
      @if (state.attemptStatus === 'GRADED') {
        <dojo-button [fill]="'outline'" class="small:w-auto block w-full" (click)="openSolutionCaptureDialog()">
          <dojo-icon [size]="16" icon="camera" class="mr-2" />
          {{ 'recapture' | transloco }}
        </dojo-button>
      }

      @if (state.selfGradingAllowed) {
        <dojo-button class="small:w-auto block w-full" (click)="selfGradeQuestionAttempt()">
          {{ 'grading.save_results' | transloco }}
        </dojo-button>
      } @else {
        <dojo-button
          class="small:w-auto block w-full"
          (click)="state.attemptStatus === 'GRADED' ? goToNextQuestion() : openSolutionCaptureDialog()"
        >
          {{
            state.attemptStatus === 'GRADED'
              ? ('examdojo.question.next_question' | transloco)
              : ('examdojo.question.upload_answer' | transloco)
          }}
        </dojo-button>
      }
    </div>
  </dojo-footer-panel>
} @else {
  <dojo-footer-panel leftButtonLabel="quit" (leftButtonClicked)="endPractice()">
    <div class="footer-panel-buttons flex items-center gap-4">
      @if (state.showColumnSpliter && state.attemptStatus !== 'GRADED') {
        <ion-button
          fill="clear"
          class="footer-panel-button split-button hidden"
          [matTooltip]="(isLayoutTwoCols() ? 'single_layout' : 'split_layout') | transloco"
          (click)="toggleColumns()"
        >
          <dojo-icon
            [icon]="isLayoutTwoCols() ? singleColumnIcon : 'line-columns'"
            [size]="isLayoutTwoCols() ? 24 : 22"
          />
        </ion-button>
      }
      <ion-button
        fill="clear"
        class="footer-panel-button"
        [matTooltip]="'formula_booklet' | transloco"
        [target]="'_blank'"
        [href]="state.formulaBooklet"
      >
        <dojo-icon [icon]="'memo-circle-info'" [size]="22"></dojo-icon>
      </ion-button>
      <ion-button
        fill="clear"
        class="footer-panel-button"
        [matTooltip]="state.questionFlagged ? ('unflag' | transloco) : ('report' | transloco)"
        dojoQuestionFlagFeedback
      >
        <dojo-icon
          [icon]="'flag'"
          [iconClass]="state.questionFlagged ? 'fill-current text-yellow-500' : ''"
          [size]="20"
        ></dojo-icon>
      </ion-button>
    </div>
    <div class="right item-center flex flex-row justify-end gap-2">
      @if (state.attemptStatus === 'GRADED') {
        <dojo-button [fill]="'outline'" class="small:w-auto block w-full" (click)="openSolutionCaptureDialog()">
          <dojo-icon [size]="16" icon="camera" class="mr-2" />
          {{ 'recapture' | transloco }}
        </dojo-button>
      }

      @if (state.selfGradingAllowed) {
        <dojo-button class="small:w-auto block w-full" (click)="selfGradeQuestionAttempt()">
          {{ 'grading.save_results' | transloco }}
        </dojo-button>
      } @else {
        <dojo-button
          class="small:w-auto block w-full"
          (click)="state.attemptStatus === 'GRADED' ? goToNextQuestion() : openSolutionCaptureDialog()"
        >
          {{
            state.attemptStatus === 'GRADED'
              ? ('examdojo.question.next_question' | transloco)
              : ('examdojo.question.upload_answer' | transloco)
          }}
        </dojo-button>
      }
    </div>
  </dojo-footer-panel>
}

<ng-template #difficultyBlock>
  <div class="flex items-center justify-center gap-4">
    @if (state.difficulty) {
      <dojo-difficulty [difficulty]="state.difficulty"></dojo-difficulty>
    }
    @if (state.question?.calculator_allowed) {
      <div
        class="flex h-[28px] w-[28px] items-center justify-center"
        [matTooltip]="'examdojo.question.calculator_allowed' | transloco"
      >
        <img src="assets/images/topic-practice/calculator/calculator-allowed.svg" />
      </div>
    } @else {
      <div
        class="flex h-[28px] w-[28px] items-center justify-center"
        [matTooltip]="'calculator_not_allowed' | transloco"
      >
        <img src="assets/images/topic-practice/calculator/calculator-not-allowed.svg" />
      </div>
    }
  </div>
</ng-template>
<ng-template #awardedMarksBlock>
  @if (state.totalMarks && state.awardedMarks && state.timeSpentAmount) {
    <dojo-question-grading-result-header
      [totalMarks]="state.totalMarks"
      [awardedMarks]="state.awardedMarks"
      [pace]="state.timeSpentAmount"
    />
  }
</ng-template>
