<ion-content>
  <div class="flex h-full flex-col">
    <div class="screen-container tiny-container my-auto" [class.grading-loading-container]="!state.gradingFailed">
      @if (state.gradingFailed) {
        @if (state.gradingError?.error_code === 'rejected_images') {
          <canvas
            #riveCanvas
            rive
            loading="lazy"
            class="loading-sensei"
            [riveFile]="'assets/images/post-activity/prohibitions.riv'"
            [artboard]="'prohibitions_2'"
            [stateMachine]="'State Machine 1'"
            [autoplay]="true"
            width="1000"
            height="1000"
          ></canvas>
          <div class="mb-4">
            <div class="screen-header">{{ 'grading.rejected_images_header' | transloco }}</div>
            <div class="screen-message">{{ 'grading.rejected_images_message' | transloco }}</div>
          </div>
        } @else if (state.gradingError?.error_code === 'grading_error') {
          <canvas
            #riveCanvas
            rive
            class="loading-sensei"
            loading="lazy"
            [riveFile]="'assets/images/sensei/shocked.riv'"
            [artboard]="'Shocked'"
            [stateMachine]="'State Machine 1'"
            [autoplay]="true"
            [style]=""
            width="1000"
            height="1000"
          ></canvas>
          <div class="mb-4">
            <div class="screen-header">{{ 'grading.grading_error_header' | transloco }}</div>
            <div class="screen-message">{{ 'grading.grading_error_message' | transloco }}</div>
          </div>
        } @else if (state.gradingError?.error_code === 'grading_limit_exceeded') {
          <canvas
            #riveCanvas
            rive
            class="loading-sensei"
            loading="lazy"
            [riveFile]="'assets/images/sensei/shocked.riv'"
            [artboard]="'Shocked'"
            [stateMachine]="'State Machine 1'"
            [autoplay]="true"
            [style]=""
            width="1000"
            height="1000"
          ></canvas>
          <div class="mb-4">
            <div class="screen-header">{{ 'grading.grading_limit_exceeded_error_header' | transloco }}</div>
            <div class="screen-message">{{ 'grading.grading_limit_exceeded_error_message' | transloco }}</div>
          </div>
        }
      } @else {
        <canvas
          #riveCanvas
          rive
          class="loading-sensei"
          loading="lazy"
          [riveFile]="'assets/images/post-activity/sensei_waiting.riv'"
          [artboard]="'Neutral'"
          [stateMachine]="'State Machine 1'"
          [autoplay]="true"
          [style]=""
          width="1000"
          height="1000"
        ></canvas>

        <img src="assets/images/character_shadow.svg" class="loading-character-shadow" />
        <div class="mb-4">
          <div class="screen-header" #messageText></div>
          <div class="screen-message">{{ state.staticMessage | transloco }}</div>
        </div>
      }
    </div>
  </div>
</ion-content>

<!-- TODO we should be able to quit the practice as of this point -->
@if (state.gradingFailed) {
  <ng-template #left>
    @if (state.gradingError?.error_code === 'grading_limit_exceeded') {
      <dojo-button fill="outline" class="block w-full md:w-fit" (click)="dismissDialog(false)">
        {{ 'quit' | transloco }}
      </dojo-button>
    }
  </ng-template>
}

<ng-template #right>
  @if (state.gradingFailed) {
    @if (state.gradingError?.error_code === 'rejected_images') {
      <dojo-button (click)="dismissDialog(true)">
        {{ 'recapture' | transloco }}
      </dojo-button>
    }
    @if (state.gradingError?.error_code === 'grading_error') {
      <dojo-button (click)="dismissDialog(false)">
        {{ 'grading.mark_my_own' | transloco }}
      </dojo-button>
    }
    @if (state.gradingError?.error_code === 'grading_limit_exceeded') {
      <dojo-button
        fill="outline"
        [customColor]="'indigo'"
        class="get-pro-button"
        (click)="state.useBillingStepper ? openBillingDialog() : openProPlanCheckout()"
      >
        {{ 'billing.get_pro' | transloco }}
      </dojo-button>
    }
  }
</ng-template>
