import { HttpDownloadProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { ToastService } from '@examdojo/core/toast';
import { isNotNullish, isNullish } from '@examdojo/core/util/nullish';
import { QuestionMark, StemStoreModel } from '@examdojo/models/question';
import { QuestionQuery, QuestionService, StemUIModel } from '@examdojo/question';
import { mapToVoid, mergeMapOnce, tapOnce } from '@examdojo/rxjs';
import {
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { ChatMessageHttpService } from './chat-message-http.service';
import {
  AgentLocalMessageStoreModel,
  ChatMessageContentStoreModel,
  ChatMessageHttpModel,
  ChatMessageOrigin,
  ChatMessageStoreModel,
  createAgentLocalMessageStoreModel,
  createInitialAgentRemoteMessageStoreModel,
  createUserMessageStoreModel,
  EngagementPhase,
  MessageResponsePart,
} from './models';
import { ChatMessageQuery, ChatMessageStore } from './store';

@Injectable({ providedIn: 'root' })
export class ChatMessageService {
  constructor(
    private readonly store: ChatMessageStore,
    private readonly chatMessageQuery: ChatMessageQuery,
    private readonly chatMessageHttpService: ChatMessageHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly questionQuery: QuestionQuery,
    private readonly questionService: QuestionService,
    private readonly toastService: ToastService,
  ) {
    this.fetchMessagesOnContextChanges().pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly fetchTrigger$ = new Subject<void>();

  triggerChatMessagesFetch() {
    this.fetchTrigger$.next();
  }

  fetchMessages({ chatId, stemId }: { chatId: number; stemId: number }): Observable<void> {
    this.store.setLoading(true);

    return this.chatMessageHttpService.fetchAllByChatIdAndMetadata(chatId, stemId).pipe(
      map((models) => models as ChatMessageHttpModel[]),
      map((httpModels) => httpModels.map((httpModel) => this.mapChatMessageHttpModelToStoreModel(httpModel))),
      tap((entities) => {
        this.store.upsertMany(entities);
      }),
      this.errorHandlerService.catchError(
        '[ChatMessageService]: could not fetch messages',
        (err) => {
          return throwError(() => err);
        },
        {
          toast: () => ({
            title: `Could not fetch messages for chat`,
          }),
        },
      ),
      mapToVoid(),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }

  resetChat() {
    this.store.reset();
  }

  setCurrentChatContext({
    engagementPhase,
    chatId,
    stemId,
  }: {
    chatId: number | null;
    engagementPhase: EngagementPhase;
    stemId: number;
  }) {
    this.store.updateProps((props) => ({ ...props, engagementPhase, chatId, stemId }));
    this.triggerChatMessagesFetch();
  }

  addUserMessage(stemId: StemStoreModel['id'], content: ChatMessageContentStoreModel): Observable<void> {
    if (!content) {
      return EMPTY;
    }

    const userMessageStoreModel = createUserMessageStoreModel(content, stemId);
    const agentLocalStoreModel = createAgentLocalMessageStoreModel(content, stemId);

    this.setLocalMessageIsSaved(userMessageStoreModel.id, false);
    this.setLocalMessageIsSaved(agentLocalStoreModel.id, false);

    this.store.create([userMessageStoreModel, agentLocalStoreModel]);

    return this.fetchResponseMessage(agentLocalStoreModel).pipe(
      tap((message) => {
        this.store.update(agentLocalStoreModel.id, {
          message: { text: message, images: [] },
        });
      }),
      tapOnce(() => {
        // As the first message comes from the BE we can be sure that the original client message
        // will be available to fetch from the server.
        this.setLocalMessageIsSaved(userMessageStoreModel.id, true);
      }),
      mergeMapOnce(() => {
        const llmChatId = this.chatMessageQuery.getProp('chatId');

        if (llmChatId) {
          return of(null);
        }

        // BE creates the chat after some time, so we need to wait for it to be created
        return timer(1000).pipe(
          switchMap(() => this.updateQuestionAttemptChat().pipe(this.errorHandlerService.catchHttpErrors(() => EMPTY))),
        );
      }),
      finalize(() => {
        // As the last message comes from the BE we can be sure that the LLM response
        // will be available to fetch from the server.
        this.setLocalMessageIsSaved(agentLocalStoreModel.id, true);
      }),
      mapToVoid(),
    );
  }

  setLocalMessageIsSaved(messageId: ChatMessageStoreModel['id'], isSaved: boolean) {
    this.store.updateProps((props) => ({
      ...props,
      savedLocalMessages: { ...props.savedLocalMessages, [messageId]: isSaved },
    }));
  }

  deleteLocallySavedMessagesForStem(stemId: StemStoreModel['id']) {
    const savedLocalMessagesMap = this.chatMessageQuery.getProp('savedLocalMessages');

    const localSavedMessagesForStem = this.chatMessageQuery.getAllUIEntities({
      filterEntity: (entity) => entity.metadata?.stem_id === stemId && savedLocalMessagesMap[entity.id],
    });

    localSavedMessagesForStem.forEach((stem) => {
      this.store.delete(stem.id);
    });
  }

  fetchResponseMessage(messageStoreModel: AgentLocalMessageStoreModel): Observable<string> {
    const currentEngagementPhase = this.chatMessageQuery.getProp('engagementPhase');
    const currentStemId = this.chatMessageQuery.getProp('stemId');

    if (isNullish(currentEngagementPhase)) {
      return throwError(() => new Error('No engagement phase found'));
    }

    if (isNullish(currentStemId)) {
      return throwError(() => new Error('No stem id found'));
    }

    const questionAttemptId = this.questionQuery.getValue().attempt?.id;

    if (isNullish(questionAttemptId)) {
      return throwError(() => new Error('No question attempt ID found'));
    }

    return this.chatMessageHttpService
      .fetchResponseMessage(
        {
          message: messageStoreModel.message.text,
          phase: currentEngagementPhase,
          images: messageStoreModel.message.images,
          stem_id: currentStemId,
        },
        questionAttemptId,
      )
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            return (event as HttpDownloadProgressEvent).partialText ?? '';
          } else if (event.type === HttpEventType.Response) {
            return (event as HttpResponse<string>).body;
          } else if (event.type === HttpEventType.ResponseHeader) {
            if (event.status === 500) {
              this.toastService.error({
                title: `An unexpected error occurred!`,
                description: 'Please try again later.',
              });

              return '';
            }
          }

          return '';
        }),
        map((msg) => {
          if (!msg?.length) {
            return '';
          }

          return this.parseMessageResponseParts(msg);
        }),
        catchError((_: unknown) => {
          return of('Error: could not get response markdown');
        }),
      );
  }

  setDefaultChatMessages(stemId: number): Observable<void> {
    const allStemMessages = this.chatMessageQuery.getAllEntities({
      filterEntity: (entity) => entity.metadata?.stem_id === stemId,
    });

    if (allStemMessages.length) {
      return EMPTY;
    }

    return this.selectDefaultChatMessages().pipe(
      tap((messages) => {
        this.store.upsertMany(messages);
      }),
      take(1),
      mapToVoid(),
    );
  }

  private fetchMessagesOnContextChanges() {
    return this.fetchTrigger$.pipe(
      switchMap(() => {
        const chatId = this.chatMessageQuery.getProp('chatId');
        const stemId = this.chatMessageQuery.getProp('stemId');

        if (!stemId) {
          return EMPTY;
        }

        return combineLatest({
          initialMessage: this.setDefaultChatMessages(stemId),
          messages: chatId ? this.fetchMessages({ stemId, chatId }) : of(null),
        });
      }),
    );
  }

  private selectDefaultChatMessages(): Observable<ChatMessageStoreModel[]> {
    const currentEngagementPhase = this.chatMessageQuery.getProp('engagementPhase');
    const currentStemId = this.chatMessageQuery.getProp('stemId');

    if (!currentStemId) {
      return throwError(() => new Error('No stem ID found'));
    }

    if (currentEngagementPhase === EngagementPhase.PreGrading) {
      return of([]);
    } else {
      return this.questionQuery.questionItems$.pipe(
        map(
          (items) =>
            items
              ?.filter((item): item is StemUIModel => item.id === currentStemId)
              ?.map((stemItem) => this.mapGradingToChatMessageStoreModel(stemItem, currentStemId))
              .filter(isNotNullish) ?? [],
        ),
      );
    }
  }

  /*
    After first message is sent - both pre and post chats are created.
  */
  private updateQuestionAttemptChat(): Observable<void> {
    const questionAttemptId = this.questionQuery.getValue().attempt?.id;

    if (isNullish(questionAttemptId)) {
      return throwError(() => new Error('No question engagement ID found'));
    }

    return this.questionService.fetchAttemptChat(questionAttemptId).pipe(
      tap((questionAttemptChat) => {
        const currentEngagementPhase = this.chatMessageQuery.getProp('engagementPhase');

        if (!currentEngagementPhase || !questionAttemptChat) {
          return;
        }

        this.store.updateProps((props) => ({
          ...props,
          chatId:
            currentEngagementPhase === EngagementPhase.PreGrading
              ? questionAttemptChat.pre_grading_llm_chat_id
              : questionAttemptChat.post_grading_llm_chat_id,
        }));
      }),
      mapToVoid(),
    );
  }

  private parseMessageResponseParts(responseString: string): string {
    const messageParts = (responseString || '').split('\n\ndata:').filter((part) => part !== '');

    messageParts[0] = messageParts[0].replace('data:', '');
    messageParts[messageParts.length - 1] = messageParts[messageParts.length - 1].trim();

    let markdown = '';

    messageParts.forEach((part) => {
      let jsonPart: MessageResponsePart;

      try {
        const jsonString = part.replace('data:', '');

        jsonPart = JSON.parse(jsonString) as MessageResponsePart;
      } catch (error) {
        // We do not want to stop the stream if one part is not parsable
        // We just log the error and continue for the remaining parts
        this.errorHandlerService.error(`[ChatMessageService]: Failed to parse JSON part`, {
          context: { part, error },
        });
        return;
      }

      if (jsonPart.type === 'error') {
        if (jsonPart.error_code === 'chat_limit_reached') {
          markdown = `You've reached your message limit! Time to take a pi break 🥧. Check back tomorrow for your next 100 messages.`;
          return;
        }

        markdown = 'An unexpected error occurred. Please try again later.';
        return;
      }

      markdown += jsonPart.content ?? '';
    });

    return markdown;
  }

  private mapGradingToChatMessageStoreModel(
    stemUIModel: StemUIModel,
    currentStemId: number,
  ): ChatMessageStoreModel | null {
    return stemUIModel.gradingResult?.stem_level_feedback
      ? createInitialAgentRemoteMessageStoreModel(
          { text: stemUIModel.gradingResult.stem_level_feedback, images: [] },
          currentStemId,
          stemUIModel.created_at,
        )
      : null;
  }

  private getMessageTextFromGradingResult(gradingResult: QuestionMark): string {
    return `
    ### Parsed Answer
    ${gradingResult.parsed_answer}

    ### Feedback
    ${gradingResult.feedback}

    ### Areas For Improvement
    ${gradingResult.improvement_areas}

    ### Concepts To Review
    ${gradingResult.concepts_to_review}
    `;
  }

  private mapChatMessageHttpModelToStoreModel({
    created_by_role,
    created_at,
    message,
    id,
    metadata,
  }: ChatMessageHttpModel): ChatMessageStoreModel {
    if (created_by_role === 'ai') {
      return {
        id: `${id}`,
        message: {
          text: message.find((m) => m.type === 'text')?.content || '',
          images: message.filter((m) => m.type === 'image').map((m) => m.content),
        },
        created_at,
        created_by_role,
        origin: ChatMessageOrigin.Remote,
        metadata,
      };
    } else {
      return {
        id: `${id}`,
        message: {
          text: message.find((m) => m.type === 'text')?.content || '',
          images: message.filter((m) => m.type === 'image').map((m) => m.content),
        },
        created_at,
        created_by_role,
        metadata,
      };
    }
  }
}
