import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import isEqual from 'lodash/isEqual';
import { distinctUntilChanged } from 'rxjs';
import { ChatMessageOrigin, ChatMessageUIModel } from '../../models';
import { AgentLocalMessageComponent } from '../agent-local-message/agent-local-message.component';
import { AgentRemoteMessageComponent } from '../agent-remote-message/agent-remote-message.component';
import { UserMessageComponent } from '../user-message/user-message.component';

@Component({
  selector: 'dojo-chat-message',
  imports: [AgentLocalMessageComponent, AgentRemoteMessageComponent, UserMessageComponent],
  templateUrl: './chat-message.component.html',
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  readonly message = input.required<ChatMessageUIModel>();

  readonly ChatMessageOrigin = ChatMessageOrigin;

  readonly state = connectState({
    message: toObservable(this.message).pipe(distinctUntilChanged((x, y) => isEqual(x, y))),
  });
}
