import { NgClass, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { TopicLevel1Query, TopicLevel2Query } from '@examdojo/category/v2';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { entries } from '@examdojo/core/util/object-utils';
import { provideFaIcons } from '@examdojo/icons';
import { STEM_DIFFICULTY_CATEGORY_TO_CARD_ICON, StemStoreModel, StimulusStoreModel } from '@examdojo/models/question';
import { PracticeActivityQuestionCandidatesStoreModel } from '@examdojo/practice-activity';
import {
  calculateQuestionDifficultyFromQuestionItems,
  QuestionItem,
  QuestionItemQuestionImageStoreModel,
} from '@examdojo/question';
import { ImageComponent } from '@examdojo/ui/image';
import { LoaderComponent } from '@examdojo/ui/loader';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoPipe } from '@jsverse/transloco';
import { map } from 'rxjs';
import { QuestionSelectionProficiencyBarComponent } from './question-selection-card-bar.component';
import {
  QuestionSelectionTopicsListComponent,
  QuestionSelectionTopicsListComponentItem,
} from './question-selection-topics-list.component';

@Component({
  selector: 'dojo-question-selection-card',
  templateUrl: './question-selection-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    IconComponent,
    ImageComponent,
    QuestionSelectionProficiencyBarComponent,
    QuestionSelectionTopicsListComponent,
    TranslocoPipe,
    LoaderComponent,
    PercentPipe,
  ],
  styleUrls: ['./question-selection-card.component.scss'],
})
export class QuestionSelectionCardComponent {
  constructor(
    private readonly topicLevel2Query: TopicLevel2Query,
    private readonly topicLevel1Query: TopicLevel1Query,
  ) {
    provideFaIcons([faCheck]);
  }

  readonly STEM_DIFFICULTY_CATEGORY_TO_CARD_ICON = STEM_DIFFICULTY_CATEGORY_TO_CARD_ICON;

  readonly isSelected = input.required<boolean>();
  readonly questionCandidate = input.required<
    PracticeActivityQuestionCandidatesStoreModel & { items: QuestionItem[] }
  >();

  readonly questionCard$ = toObservable(this.questionCandidate).pipe(
    map((questionCandidate) => {
      const questionItems = questionCandidate.items;
      const totalMarks = this.calculateQuestionTotalMarks(questionItems?.filter((item) => item.type === 'stem') ?? []);

      const listOfTopicLevel2 = entries(questionCandidate.metadata.topics_level_02_proficiencies).map(
        ([topicLevel2Id, proficiency]) => {
          const topicLevel2 = this.topicLevel2Query.getEntity(+topicLevel2Id);
          const topicLevel1 = topicLevel2?.topic_level_01_id
            ? this.topicLevel1Query.getEntity(topicLevel2?.topic_level_01_id)
            : null;

          return {
            topicLevel2Id,
            proficiency,
            topicLevel2,
            topicLevel1,
            icon: {
              name: topicLevel2?.icon as Icon,
              color: topicLevel1?.color_name,
            },
          } as QuestionSelectionTopicsListComponentItem;
        },
      );
      const questionDifficulty = calculateQuestionDifficultyFromQuestionItems(questionItems);

      const value = {
        questionCandidate,
        questionItems,
        totalMarks,
        listOfTopicLevel2,
        questionDifficulty,
        proficiency: {
          priorScore: questionCandidate.metadata.prior_predicted_score,
          predictedScore: questionCandidate.metadata.predicted_score,
          gain:
            (questionCandidate.metadata.predicted_score - questionCandidate.metadata.prior_predicted_score) /
            questionCandidate.metadata.prior_predicted_score,
        },
      };

      return value;
    }),
  );

  readonly state = connectState({
    questionCard: this.questionCard$,
  });

  readonly questionItems = model<Array<StimulusStoreModel | StemStoreModel | QuestionItemQuestionImageStoreModel>>();

  private calculateQuestionTotalMarks(stemQuestionItems: StemStoreModel[]): number {
    return stemQuestionItems.reduce((sum, item) => sum + item.totalMarks, 0);
  }
}
