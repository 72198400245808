import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, ProviderToken, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { AnimationDuration, getAnimationAppearDisappear } from '@examdojo/animation';
import { DebuggingContextService } from '@examdojo/core/debugging';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { QuestionStoreModel } from '@examdojo/models/question';
import {
  PracticeActivityQuery,
  PracticeActivityQuestionCandidatesStoreModel,
  PracticeActivityService,
  PracticeActivityStatus,
} from '@examdojo/practice-activity';
import {
  PracticeActivityStrategy,
  QuestionAttemptStoreModel,
  QuestionQuery,
  QuestionService,
  SolutionCaptureQueryParam,
} from '@examdojo/question';
import { LoaderComponent } from '@examdojo/ui/loader';
import { assertNonNullable } from '@examdojo/util/assert';
import { IonContent } from '@ionic/angular/standalone';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  Observable,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  QuestionAttemptRealtimeService,
  QuestionV2Component,
  removeSolutionCaptureQueryParamWithoutReloadState,
} from '../../../question-v2';
import { PostActivityScreensService } from '../post-activity-screens.service';
import { QuestionSelectionComponent } from '../question-selection/question-selection.component';
import { TopicPracticeV2Service } from '../topic-practice-v2.service';
import { CloseSolutionCaptureDialogEffectService } from './effects/close-solution-capture-dialog-effect.service';
import { TopicPracticeDialogService } from './topic-practice-dialog.service';

@Component({
  selector: 'dojo-topic-practice-dialog-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './topic-practice-dialog-container.component.html',
  imports: [IonContent, QuestionSelectionComponent, QuestionV2Component, LoaderComponent],
  animations: [
    getAnimationAppearDisappear({
      leave: AnimationDuration.None,
    }),
  ],
  host: { class: 'flex flex-col h-full' },
  providers: [PostActivityScreensService, QuestionAttemptRealtimeService, CloseSolutionCaptureDialogEffectService],
})
export class TopicPracticeDialogContainerComponent {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly topicPracticeService: TopicPracticeV2Service,
    private readonly practiceActivityService: PracticeActivityService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly practiceActivityQuery: PracticeActivityQuery,
    private readonly destroyRef: DestroyRef,
    private readonly questionService: QuestionService,
    private readonly questionQuery: QuestionQuery,
    private readonly debuggingContextService: DebuggingContextService,
    private readonly topicPracticeDialogService: TopicPracticeDialogService,
  ) {
    merge(
      this.fetchPracticeActivityCandidatesOnPracticeActivityChange(),
      this.fetchQuestionAttemptOnPracticeActivityChange(),
      this.setSolutionCaptureQueryParamWithoutReload(),
      this.debuggingContextService.syncDebuggingContext('Activity id', this.inProgressPracticeActivityId$),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  private readonly instantiatedServices = [CloseSolutionCaptureDialogEffectService, QuestionAttemptRealtimeService].map(
    (service: ProviderToken<unknown>) => inject(service),
  );

  readonly notStartedPracticeActivityId$: Observable<string | undefined> =
    this.practiceActivityQuery.notStartedPracticeActivityId$;

  readonly inProgressPracticeActivityId$: Observable<string | undefined> =
    this.practiceActivityQuery.inProgressPracticeActivityId$;

  readonly questionCandidates = signal<PracticeActivityQuestionCandidatesStoreModel[] | null>(null);
  readonly isQuestionCandidatesLoaded$$ = new BehaviorSubject<boolean>(false);
  readonly isQuestionAttemptLoaded$$ = new BehaviorSubject<boolean>(false);

  private readonly isPracticeActivityLoaded$ = combineLatest([
    this.questionQuery.questionLoaded$,
    this.isQuestionCandidatesLoaded$$,
    this.inProgressPracticeActivityId$,
    this.notStartedPracticeActivityId$,
    this.practiceActivityService.creatingPracticeActivity$,
  ]).pipe(
    map(
      ([
        isQuestionAttemptLoaded,
        isQuestionCandidatesLoaded,
        inProgressPracticeActivityId,
        notStartedPracticeActivityId,
        creatingPracticeActivity,
      ]) => {
        if (creatingPracticeActivity) {
          return false;
        }

        if (!inProgressPracticeActivityId && !notStartedPracticeActivityId) {
          // closing the dialog
          return true;
        }

        return (
          (isQuestionAttemptLoaded && !!inProgressPracticeActivityId) ||
          (isQuestionCandidatesLoaded && !!notStartedPracticeActivityId)
        );
      },
    ),
    distinctUntilChanged(),
    debounceTime(200),
  );

  readonly state = connectState({
    inProgressActivityId: this.inProgressPracticeActivityId$,
    notStartedActivityId: this.notStartedPracticeActivityId$,
    questionLoaded: this.questionQuery.questionLoaded$,
    isLoading: this.isPracticeActivityLoaded$.pipe(
      map((loaded) => !loaded),
      startWith(true),
    ),
  });

  handleQuestionSelected(id: QuestionStoreModel['id']) {
    this.isQuestionCandidatesLoaded$$.next(false);

    return this.notStartedPracticeActivityId$
      .pipe(
        filter(isNotNullish),
        take(1),
        switchMap((practiceActivityId) =>
          this.practiceActivityService
            .setPracticeActivityQuestion(id, practiceActivityId)
            // TODO: a custom error title would be nice.
            .pipe(this.errorHandlerService.catchHttpErrors(() => EMPTY)),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  handleCompletedQuestion(action: 'continue' | 'stop') {
    const activityId = this.state.inProgressActivityId || this.state.notStartedActivityId;
    assertNonNullable(activityId, 'activityId');

    // TODO: change when new statuses will be introduced
    const createNewPracticeActivity = this.getNewPracticeActivityConfig(action);

    if (createNewPracticeActivity) {
      this.practiceActivityService.creatingPracticeActivity$$.next(true);
    }

    const practiceActivityStatusToUpdate = this.getPracticeActivityStatusBasedOnQuestionAttemptStatus(
      this.questionQuery.getValue()?.attempt?.status,
    );

    this.practiceActivityService
      .completePracticeActivityWithStatus(activityId, practiceActivityStatusToUpdate)
      .pipe(
        tap(() => {
          // Immediately reset the question candidates and attempt to avoid showing intermediate states.
          this.questionCandidates.set(null);
          this.questionService.resetQuestionContext();
        }),
        switchMap(() => {
          if (!createNewPracticeActivity) {
            return this.closeDialogAndReturnHome();
          }

          return this.practiceActivityService
            .create({
              topics_level_02: createNewPracticeActivity.topicIds,
              strategy: createNewPracticeActivity.strategy,
            })
            .pipe(
              tap(() => {
                this.practiceActivityService.creatingPracticeActivity$$.next(false);
              }),
            );
        }),
        // If the practice activity creation fails, we should dismiss the dialog and navigate to the home page.
        // Otherwise the user will be stuck in the dialog forever.
        this.errorHandlerService.catchHttpErrors(() => this.closeDialogAndReturnHome()),
        // Do not use takeUntilDestroyed here
        // as after closing the dialog we still want to finish the flow.
      )
      .subscribe();
  }

  private getNewPracticeActivityConfig(action: 'continue' | 'stop') {
    if (action === 'stop') {
      return null;
    }

    const lastSelection = this.topicPracticeService.topicLevel2SelectionStorageManager.getValue();
    const topicIds = lastSelection?.topicIds ?? [];
    const strategy = lastSelection?.strategy ?? PracticeActivityStrategy.ScoreImpact;

    if (!topicIds?.length) {
      return null;
    }

    return { topicIds, strategy };
  }

  private closeDialogAndReturnHome() {
    return this.topicPracticeDialogService.dismissPracticeActivityDialog();
  }

  private fetchPracticeActivityCandidatesOnPracticeActivityChange() {
    return this.notStartedPracticeActivityId$.pipe(
      filter(isNotNullish),
      switchMap((id) => this.practiceActivityService.getPracticeActivityQuestionCandidates(id)),
      this.errorHandlerService.catchHttpErrors(() => EMPTY),
      tap((candidates) => {
        if (candidates.length === 1) {
          this.handleQuestionSelected(candidates[0].question_id);
        } else {
          this.questionCandidates.set(candidates);
          this.isQuestionCandidatesLoaded$$.next(true);
        }
      }),
    );
  }

  private fetchQuestionAttemptOnPracticeActivityChange() {
    return this.inProgressPracticeActivityId$.pipe(
      filter(isNotNullish),
      switchMap((id) => this.practiceActivityQuery.selectEntity(id)),
      map((activity) => activity?.question_attempt_id),
      filter(isNotNullish),
      distinctUntilChanged(),
      switchMap((attemptId) => this.questionService.fetchQuestionContext(attemptId)),
    );
  }

  private setSolutionCaptureQueryParamWithoutReload() {
    return this.route.queryParamMap.pipe(
      tap((params) => {
        if (params.get(SolutionCaptureQueryParam)) {
          this.questionService.setOpenCaptureDialogFlag(true);
          this.location.replaceState(removeSolutionCaptureQueryParamWithoutReloadState());
        }
      }),
    );
  }

  private getPracticeActivityStatusBasedOnQuestionAttemptStatus(
    questionAttemtStatus: QuestionAttemptStoreModel['status'] | undefined,
  ): PracticeActivityStatus {
    return !questionAttemtStatus || questionAttemtStatus === 'PENDING' ? 'DISCARDED' : 'COMPLETED';
  }
}
