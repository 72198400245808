import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FullscreenFlowDialogService } from './fullscreen-flow-dialog.service';
import { FullscreenFlowComponent } from './fullscreen-flow.component';
import { FullscreenFlowStep } from './fullscreen-flow.model';

@Injectable({
  providedIn: 'root',
})
export class FullscreenFlowService {
  constructor(private readonly fullscreenFlowDialogService: FullscreenFlowDialogService) {}

  readonly dismissed$ = this.fullscreenFlowDialogService.dismissed$;
  open(steps: Array<FullscreenFlowStep<FormGroup>>, initialStep?: number) {
    return this.fullscreenFlowDialogService.openDialog({
      options: {
        componentProps: {
          steps,
          initialStep,
        } satisfies Partial<InstanceType<typeof FullscreenFlowComponent>>,
      },
    });
  }

  isOpen() {
    return this.fullscreenFlowDialogService.isOpen();
  }

  dismiss() {
    return this.fullscreenFlowDialogService.dismiss();
  }
}
