import { Injectable } from '@angular/core';
import { distinctUntilArrayChanged } from '@examdojo/core/rxjs';
import { StemStoreModel } from '@examdojo/models/question';
import { QueryEntity } from '@examdojo/state';
import { combineLatest, distinctUntilChanged, map, Observable, of, switchMap } from 'rxjs';
import { ChatMessageOrigin, ChatMessageState, ChatMessageStoreModel, ChatMessageUIModel } from '../models';
import { ChatMessageStore } from './chat-message.store';

@Injectable({ providedIn: 'root' })
export class ChatMessageQuery extends QueryEntity<ChatMessageState, ChatMessageStoreModel, ChatMessageUIModel, 'id'> {
  constructor(chatMessageStore: ChatMessageStore) {
    super(chatMessageStore);
  }

  selectResponseLoadingForStem(stemId: StemStoreModel['id']): Observable<boolean> {
    return combineLatest([this.selectMessages(stemId), this.selectProp('savedLocalMessages')]).pipe(
      map(([stemMessages, savedLocalMessages]) => {
        return stemMessages.some(
          (message) => message.origin === ChatMessageOrigin.Local && !savedLocalMessages[message.id],
        );
      }),
      distinctUntilChanged(),
    );
  }

  selectMessagesForCurrentStem(): Observable<ChatMessageUIModel[]> {
    return this.selectProp('stemId').pipe(
      switchMap((stemId) => (stemId ? this.selectMessages(stemId) : of([]))),
      distinctUntilArrayChanged(),
    );
  }

  selectIsLoading(messageId: ChatMessageStoreModel['id']): Observable<boolean> {
    return this.selectProp('savedLocalMessages').pipe(map((savedLocalMessages) => !savedLocalMessages[messageId]));
  }

  selectResponseLoadingForCurrentStem(): Observable<boolean> {
    return this.selectProp('stemId').pipe(
      switchMap((stemId) => (stemId ? this.selectResponseLoadingForStem(stemId) : of(false))),
    );
  }

  selectMessages(stemId: number): Observable<ChatMessageUIModel[]> {
    return this.selectAllEntities({
      filterEntity: (entity) => entity.metadata?.stem_id === stemId,
    });
  }
}
