import { Injectable } from '@angular/core';
import { LocalStorageManager } from '@examdojo/browser/local-storage';
import { TopicLevel2StoreModel } from '@examdojo/category/v2';
import { PracticeActivityStrategy } from '@examdojo/question';
import { TOPIC_PRACTICE_LOCAL_STORAGE_KEY } from './topic-practice-v2.model';

@Injectable({ providedIn: 'root' })
export class TopicPracticeV2Service {
  readonly topicLevel2SelectionStorageManager = new LocalStorageManager<{
    topicIds: Array<TopicLevel2StoreModel['id']>;
    strategy: PracticeActivityStrategy;
  }>(TOPIC_PRACTICE_LOCAL_STORAGE_KEY);
}
