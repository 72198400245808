import { SolutionCaptureQueryParam } from '@examdojo/question';

export interface QuestionQueryParams {
  [SolutionCaptureQueryParam]?: boolean;
}

export function removeSolutionCaptureQueryParamWithoutReloadState() {
  const url = new URL(window.location.href);
  url.searchParams.delete(SolutionCaptureQueryParam);

  return url.pathname + url.search;
}

export function shouldOpenSolutionCaptureDialog() {
  const url = new URL(window.location.href);
  return !!url.searchParams.get(SolutionCaptureQueryParam);
}
