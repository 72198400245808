import { Directive, effect, inject, OnInit, signal, TemplateRef, viewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlsOf } from '@examdojo/angular/forms';
import { SwiperSlideDirective, SwiperStepperComponent } from '@examdojo/core/swiper';
import { combineLatest, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { FullscreenFlowComponent } from './fullscreen-flow.component';

@Directive()
export abstract class FullscreenFlowStepComponent<T extends object = object> implements OnInit {
  constructor() {
    effect(
      () => {
        const leftTemplate = this.leftTemplateRef();
        const rightTemplate = this.rightTemplateRef();

        if (this.isActive()) {
          this.fullscreenFlowComponent.leftButtonTemplateRef.set(leftTemplate);
          this.fullscreenFlowComponent.rightButtonTemplateRef.set(rightTemplate);
        } else {
          this.fullscreenFlowComponent.leftButtonTemplateRef.set(undefined);
          this.fullscreenFlowComponent.rightButtonTemplateRef.set(undefined);
        }
      },
      { allowSignalWrites: true },
    );
  }

  readonly isActive = signal(false);
  readonly isRendered = signal(false);

  protected readonly fullscreenFlowComponent = inject(FullscreenFlowComponent);
  protected readonly swiperStepperComponent = inject(SwiperStepperComponent);
  protected readonly swiperSlideDirective = inject(SwiperSlideDirective);

  readonly leftTemplateRef = viewChild<TemplateRef<unknown>>('left');
  readonly rightTemplateRef = viewChild<TemplateRef<unknown>>('right');

  readonly stepForm$ = combineLatest([this.swiperStepperComponent.form$, this.swiperSlideDirective.controlName$]).pipe(
    map(([form, controlName]) => {
      if (!controlName) {
        return undefined;
      }
      return form.get(controlName) as FormGroup<FormControlsOf<T>>;
    }),
  );

  readonly stepFormValue$ = this.stepForm$.pipe(
    switchMap((control) => {
      if (!control) {
        return of(undefined);
      }
      return control.valueChanges.pipe(startWith(control.value));
    }),
  );

  readonly stepFormStatus$ = this.stepForm$.pipe(
    switchMap((form) => {
      if (!form) {
        return of(undefined);
      }
      return form.statusChanges.pipe(startWith(form.status));
    }),
    distinctUntilChanged(),
  );

  ngOnInit(): void {
    this.isRendered.set(true);
  }

  goToNextStep() {
    return this.swiperStepperComponent.goNextOrSubmit();
  }
}
