import { Component, computed, input, output } from '@angular/core';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { ImagePreviewDirective } from '@examdojo/ui/image-preview';

@Component({
  selector: 'dojo-chat-images-list-item',
  templateUrl: './chat-images-list-item.component.html',
  styles: [
    `
      .chat-images-remove-button ::ng-deep .ion-icon-button.button-solid {
        --background: theme('colors.stone.500');
        --background-hover: theme('colors.stone.400');
        --background-focused: theme('colors.stone.500');
        --border-color: white;
        --color: white;

        &:hover {
          --background: theme('colors.stone.400');
          --background-hover: theme('colors.stone.400');
        }

        &.ion-activated,
        &.ion-focused {
          --border-color: theme('colors.green.400');

          &:hover {
            --border-color: theme('colors.green.400');
          }
        }
      }
    `,
  ],
  imports: [IconButtonComponent, ImagePreviewDirective],
  host: {
    class: 'relative',
  },
})
export class ChatImagesListItemComponent {
  readonly base64String = input.required<string>();

  readonly editable = input<boolean>(false);

  readonly removeImage = output();

  // TODO: update data:{{format}} after API will support image type as input
  readonly dataUrl = computed(() => `data:text/plain;base64,${this.base64String()}`);
}
