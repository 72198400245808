import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { GradedMethod, SelfGradableMethod } from '@examdojo/question';
import { TranslocoPipe } from '@jsverse/transloco';
import { QuestionGradingMethodResultRowComponent } from './question-grading-method-result-row.component';

@Component({
  selector: 'dojo-question-grading-method-result',
  template: `
    <div class="method-group-table bg-neutral-0 w-full rounded-[0.75rem] border border-neutral-200">
      <div class="text-secondary font-medium-exam flex w-full text-sm">
        <div class="left-cell w-[70%] border-r border-neutral-200 px-4 py-3">
          {{ 'grading.mark_scheme' | transloco }}
        </div>
        <div class="right-cell w-[30%] px-4 py-3">{{ 'grading.your_marks' | transloco }}</div>
      </div>
      @for (markGroup of methodMarkscheme().mark_groups; track $index; let markGroupIndex = $index) {
        @for (
          markGroupAlternative of markGroup.mark_group_alternatives;
          track $index;
          let markGroupAlternativeIndex = $index
        ) {
          @if (!selfGradingAllowed() && methodAwarded()) {
            @if (gradedMethod(); as gradedMethod) {
              @if (
                gradedMethod.mark_groups[markGroupIndex] &&
                gradedMethod.mark_groups[markGroupIndex].mark_group_alternatives &&
                gradedMethod.mark_groups[markGroupIndex].mark_group_alternatives[markGroupAlternativeIndex] &&
                gradedMethod.mark_groups[markGroupIndex].mark_group_alternatives[markGroupAlternativeIndex].awarded
              ) {
                <dojo-question-grading-method-result-row
                  [markGroupAlternative]="markGroupAlternative"
                  [markGroupAlternativeMarks]="
                    gradedMethod.mark_groups[markGroupIndex].mark_group_alternatives[markGroupAlternativeIndex].marks
                  "
                  [showName]="showAlternatives()"
                  [showMarks]="true"
                />
              } @else if (showAlternatives()) {
                <dojo-question-grading-method-result-row
                  [markGroupAlternative]="markGroupAlternative"
                  [markGroupAlternativeMarks]="null"
                  [showName]="true"
                  [showMarks]="false"
                />
              }
            }
          } @else {
            <dojo-question-grading-method-result-row
              [markGroupAlternative]="markGroupAlternative"
              [markGroupAlternativeMarks]="null"
              [showName]="true"
              [showMarks]="false"
              [selfGradingAllowed]="selfGradingAllowed()"
            />
          }
        }
      }
    </div>
  `,
  imports: [QuestionGradingMethodResultRowComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionGradingMethodResultComponent {
  readonly gradedMethod = input<GradedMethod>();
  readonly methodMarkscheme = input.required<SelfGradableMethod>();
  readonly methodAwarded = input.required<boolean>();
  readonly selfGradingAllowed = input<boolean>();

  readonly showAlternatives = input.required<boolean>();
}
