import { Component, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { connectState } from '@examdojo/angular/util';
import { MarksEarnedBarComponent, PaceBoundaryBarComponent, PaceBoundaryQuery } from '@examdojo/category/v2';
import { DateTimeService } from '@examdojo/core/date-time';
import { InternationalizationService, SupportedLanguage } from '@examdojo/core/i18n';
import { IonButton } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'dojo-question-grading-result-header',
  template: `
    <div class="flex items-center justify-center gap-3 md:justify-start">
      @if (awardedMarks() && totalMarks()) {
        <ion-button
          [fill]="'clear'"
          [matMenuTriggerFor]="marksMenu"
          #menuTrigger="matMenuTrigger"
          class="grading-button"
        >
          <div class="flex w-[36px] items-center justify-center">
            <img src="assets/images/post-activity/marks-earned.svg" />
          </div>
          <span class="ml-2 font-bold text-green-500"> {{ awardedMarks() }}/{{ totalMarks() }} </span>
        </ion-button>

        <mat-menu #marksMenu="matMenu" class="custom-mat-menu wide-width">
          <div class="flex flex-col gap-4 p-6">
            <div class="text-primary-exam text-lg font-semibold">{{ 'grading.total_marks' | transloco }}</div>
            <div class="text-secondary">
              <div class="flex gap-4">
                <!--<div>diagr</div>-->
                <!--todo Tanya-->
                <div>
                  <span>{{ 'grading.you_got_marks.1' | transloco }}</span>
                  <span class="text-green-600">
                    <span
                      class="font-medium-exam"
                      [innerHTML]="
                        'grading.you_got_marks.2' | transloco: { awarded: awardedMarks(), total: totalMarks() }
                      "
                    ></span>
                  </span>
                </div>
              </div>
            </div>
            <dojo-marks-earned-bar [marksEarned]="awardedMarks()" [totalMarks]="totalMarks()" />
          </div>
        </mat-menu>
      }
      @if (state.paceBoundariesTranslationKey) {
        <ion-button [fill]="'clear'" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" class="grading-button">
          <div class="flex w-[36px] items-center justify-center">
            <img src="assets/images/post-activity/time-spent.svg" />
          </div>
          <span class="ml-2 font-bold text-indigo-500">
            {{ state.timeSpent }}
          </span>
        </ion-button>

        <mat-menu #menu="matMenu" class="custom-mat-menu wide-width">
          <div class="flex flex-col gap-4 p-6">
            <div class="text-primary-exam text-lg font-semibold">{{ 'grading.pace' | transloco }}</div>
            <span
              class="text-secondary pace-boundaries"
              [innerHTML]="state.paceBoundariesTranslationKey | transloco: { durationString: state.timeSpent }"
            >
            </span>
            <dojo-pace-boundary-bar [pace]="state.pacePerMark" />
          </div>
        </mat-menu>
      }
    </div>
  `,
  styleUrl: './question-grading-result-header.component.scss',
  imports: [TranslocoPipe, PaceBoundaryBarComponent, MatMenuTrigger, MatMenu, IonButton, MarksEarnedBarComponent],
})
export class QuestionGradingResultHeaderComponent {
  constructor(
    private readonly paceBoundaryQuery: PaceBoundaryQuery,
    private readonly dateTimeService: DateTimeService,
    private readonly internationalizationService: InternationalizationService,
  ) {}

  readonly pace = input.required<number>();
  readonly awardedMarks = input.required<number>();
  readonly totalMarks = input.required<number>();

  readonly pace$ = toObservable(this.pace);
  readonly totalMarks$ = toObservable(this.totalMarks);

  readonly pacePerMark$ = combineLatest([this.pace$, this.totalMarks$]).pipe(
    map(([pace, totalMarks]) => Number((pace / totalMarks).toFixed(0))),
    distinctUntilChanged(),
  );

  readonly paceBoundariesTranslationKey$ = this.pacePerMark$.pipe(
    map((pacePerMark) => this.paceBoundaryQuery.getPaceBoundaryByPaceMark(pacePerMark)),
    map((paceBoundary) => {
      if (paceBoundary && paceBoundary.bucket_comment) {
        const translationKey = `pace_boundaries.${paceBoundary.course_level}.${paceBoundary.bucket_num}`;
        this.internationalizationService.setTemporaryTranslation(
          translationKey,
          paceBoundary.bucket_comment as Record<SupportedLanguage, string>,
        );

        return translationKey;
      }
      return null;
    }),
  );

  readonly state = connectState({
    paceBoundariesTranslationKey: this.paceBoundariesTranslationKey$,
    pacePerMark: this.pacePerMark$,
    timeSpent: this.pacePerMark$.pipe(
      map((x) => {
        if (!x) {
          return '';
        }

        return this.dateTimeService.formatDurationShort(x);
      }),
    ),
  });
}
